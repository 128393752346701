import { BrowserModule } from '@angular/platform-browser';
//import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { OverviewComponent } from './overview/overview.component';
import { NewcaseComponent } from './case/newcase/newcase.component';
import { CaseComponent } from './case/case.component';
import { AuthServiceService } from './auth-service.service';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { Ship1Component } from './ships/ship1/ship1.component';
import { Ship2Component } from './ships/ship2/ship2.component';
import { Ship3Component } from './ships/ship3/ship3.component';
import { Ship4Component } from './ships/ship4/ship4.component';
import { Ship5Component } from './ships/ship5/ship5.component';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { EmbeddingPipe } from './embedding.pipe';
import { RoundPipe } from './round.pipe';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VesselComponent } from './vessel/vessel.component';
import { VideoComponent } from './video/video.component';
import { PointComponent } from './point/point.component';
import { AddPointsComponent } from './point/add-points/add-points.component';
import { VesselOverviewComponent } from './vessel-overview/vessel-overview.component';
import { InteractiveReportComponent } from './interactive-report/interactive-report.component';
import { IntertracReportComponent } from './intertrac-report/intertrac-report.component';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading/loading.component';
import { CookieService } from 'ngx-cookie-service';
import { UserListComponent } from './user-list/user-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AttachUserComponent } from './case/attach-user/attach-user.component';
import { FilterPipe } from './pipe/filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OverviewComponent,
    NewcaseComponent,
    CaseComponent,
    HeaderComponent,
    ProfileComponent,
    Ship1Component,
    Ship2Component,
    Ship3Component,
    Ship4Component,
    Ship5Component,
    EmbeddingPipe,
    RoundPipe,
    VesselComponent,
    VideoComponent,
    PointComponent,
    AddPointsComponent,
    VesselOverviewComponent,
    InteractiveReportComponent,
    IntertracReportComponent,
    LoadingComponent,
    UserListComponent,
    AddUserComponent,
    AttachUserComponent,
    FilterPipe,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      closeButton: true
    }),
    DragDropModule,
    PdfViewerModule,
    MatTableModule,
    MatCardModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  providers: [AuthServiceService,
    CookieService,
    {
      provide: CONFIG, useValue: {
        'allow_google_signals': false,
        'allow_ad_personalization_signals': false,
        'restricted_data_processing': true,
        'anonymize_ip': true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
