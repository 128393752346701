import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform (input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

}
