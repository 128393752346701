<app-header></app-header>
<div class="title-bar">
  <span>New Inspection</span>
</div>
<div [ngClass]="{'display-content': content == 'details'}" class="content">
  <div class="title-container">
    <h1 class="title">New Inspection</h1>
    <div class="buttons">
      <div (click)="saveInspectionDetails()" [ngClass]="{'can-submit': canSaveDetails()}" class="save-button">
        <span>Save Details</span>
      </div>
      <div class="back-button" (click)="overview()">
        Back
      </div>
    </div>
  </div>
  <div class="vessel-details">
    <div class="vessel-input">
      <span class="details-title">Inspection Details</span>
      <div class="row">
        <label for="vessel">IMO:</label>
        <input list="vesselNames" id="vessel" [(ngModel)]="vessel" (change)="selectVessel(vessel)" placeholder="Type to select a vessel..." />
          <datalist name="vesselNames" id="vesselNames" [(ngModel)]="vessel">
            <option value="{{vessel.imo}}" *ngFor="let vessel of vessels"></option>
          </datalist>
      </div>
      <div class="row">
        <label for="vesselName">Inspection Date:</label>
        <input [(ngModel)]="caseDate" id="caseDate" max="&lt;?php echo date('Y-m-d'); ?&gt;"
          placeholder="Case Date" required="" type="datetime-local" (change)="pastDate($event)">
      </div>
      <div class="row">
        <label for="countrySelected">Country of Inspection:</label>
        <div class="left">
          <input list="country" id="countrySelected" [(ngModel)]="countrySelected" (change)="selectCountry(countrySelected)" placeholder="Type to search a country..." class="btn-row"/>
            <datalist name="country" id="country" [(ngModel)]="countrySelected" >
              <option *ngFor="let country of countries" value="{{country.country}}"></option>
            </datalist>
            <div class="btn" (click)="getPorts()" [ngClass]="{'disabled': countrySelected == null}">
              <span *ngIf="!portSearching">Search Ports</span>
              <div class="loader" *ngIf="portSearching"></div>
            </div>
        </div>
      </div>
      <div class="row" *ngIf="portSearch">
        <label for="port">Port:</label>
        <input list="ports" id="port" [(ngModel)]="port" (change)="selectPort(port)" placeholder="Type to search port..."/>
          <datalist name="ports" id="ports" [(ngModel)]="port" >
            <option *ngFor="let port of ports" value="{{port.name}}"></option>
          </datalist>
      </div>
  <!--     <div class="row" *ngIf="countrySelected != null">
        <label for="citySelected">City of Inspection:</label>
        <input list="city" id="citySelected" [(ngModel)]="citySelected" (change)="selectCity(citySelected)" placeholder="Type to search a city..."/>
          <datalist name="city" id="city" [(ngModel)]="citySelected" >
            <option *ngFor="let city of countrySelectedArray.cities" value="{{city}}"></option>
          </datalist>
      </div> -->
      <div class="row">
        <label for=" visibility">Water Visibility:</label>
        <input list="visibilities" id=" visibility" [(ngModel)]="visibility" (change)="selectVisibility(visibility)" placeholder="Type to search water visibility..."/>
          <datalist name="visibilities" id="visibilities" [(ngModel)]=" visibility" >
            <option *ngFor="let visibility of visibilities" value="{{visibility.visibility}}"></option>
          </datalist>
      </div>
      <div class="row">
        <label for="purpose">Inspection Purpose:</label>
        <input list="purposes" id="purpose" [(ngModel)]="purpose" (change)="selectPurpose(purpose)" placeholder="Type to search inspection purpose..."/>
          <datalist name="purposes" id="purposes" [(ngModel)]="purpose" >
            <option *ngFor="let purpose of purposes" value="{{purpose.purpose}}"></option>
          </datalist>
      </div>
      <div class="row">
        <label for="inspector">Operator/Inspector:</label>
        <div class="left">
          <input list="inspectors" id="inspector" [(ngModel)]="inspector" (change)="selectInspector(inspector)" placeholder="Type to search water inspector..."/>
            <datalist name="inspectors" id="inspectors" [(ngModel)]="inspector" >
              <option *ngFor="let inspector of inspectors" value="{{inspector.name}}"></option>
            </datalist>
            <div class="btn" (click)="addInspector()" [ngClass]="{'disabled': inspector == null}">
              <span>Add Inspector</span>
            </div>
        </div>
      </div>
      <div class="row inspectors" *ngIf="inspectorsSelected.length > 0">
        <div class="inspectorsContainer">
          <div class="inspector" *ngFor="let inspectorSel of inspectorsSelected" (click)="removeInspector(inspectorSel)">
            <div class="bubble">
              <span>{{inspectorSel}}</span>
            </div>
            <div class="bubble">
              <img src="../../../assets/icons/noun-cross-1769549-004D99.png" alt="Remove inspector" >
            </div>
          </div>
        </div>
      </div>
      <mat-divider *ngIf="inspectorsSelected.length > 0"></mat-divider>
      <div class="row">
        <label for="weather">Weather:</label>
        <div class="left">
          <input list="weathers" id="weather" [(ngModel)]="weather" (change)="selectWeather(weather)" placeholder="Type to search weather..."/>
            <datalist name="weathers" id="weathers" [(ngModel)]="weather" >
              <option *ngFor="let weather of weathers" value="{{weather.weather}}"></option>
            </datalist>
            <div class="btn" (click)="addWeather()" [ngClass]="{'disabled': weather == null}">
              <span>Add Weather</span>
            </div>
        </div>
      </div>
      <div class="row inspectors" *ngIf="weatherSelected.length > 0">
        <div class="inspectorsContainer">
          <div class="inspector" *ngFor="let weatherSel of weatherSelected" (click)="removeWeather(weatherSel)">
            <div class="bubble">
              <span>{{weatherSel}}</span>
            </div>
            <div class="bubble">
              <img src="../../../assets/icons/noun-cross-1769549-004D99.png" alt="Remove weather" >
            </div>
          </div>
        </div>
      </div>
      <mat-divider *ngIf="weatherSelected.length > 0"></mat-divider>
      <div class="row">
        <label for="coatingVert">Coating Verticals:</label>
        <input list="coatingsVert" id="coatingVert" [(ngModel)]="coatingVert" (change)="selectCoatingVert(coatingVert)" placeholder="Type to search coating..."/>
          <datalist name="coatingsVert" id="coatingsVert" [(ngModel)]="coatingVert" >
            <option *ngFor="let coating of coatings" value="{{coating.coating}}"></option>
          </datalist>
      </div>
      <div class="row">
        <label for="coatingFlat">Coating Flats:</label>
        <input list="coatingsFlat" id="coatingFlat" [(ngModel)]="coatingFlat" (change)="selectCoatingFlat(coatingFlat)" placeholder="Type to search coating..."/>
          <datalist name="coatingsFlat" id="coatingsFlat" [(ngModel)]="coatingFlat" >
            <option *ngFor="let coating of coatings" value="{{coating.coating}}"></option>
          </datalist>
      </div>
      <div class="row">
        <label for="coatingBoot">Coating Boottop:</label>
        <input list="coatingsBoot" id="coatingBoot" [(ngModel)]="coatingBoot" (change)="selectCoatingFlat(coatingFlat)" placeholder="Type to search coating..."/>
          <datalist name="coatingsBoot" id="coatingsBoot" [(ngModel)]="coatingBoot" >
            <option *ngFor="let coating of coatings" value="{{coating.coating}}"></option>
          </datalist>
      </div>
    </div>
  </div>
</div>
