import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { HeaderService } from '../service/header.service';
import swal from 'sweetalert';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { safeGet } from '@firebase/util';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users = null;
  currentUser = {field: "", direction: 0};

  constructor(
    private authService: AuthServiceService,
    private db: AngularFirestore,
    private router: Router,
    private headerService: HeaderService,
    private afAuth: AngularFireAuth,
    private userService: UserService
    ) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "user";
    this.getUsers();
    this.tempGetUsers();
  }

  getUsers(){
    this.users = this.db.collection('user').valueChanges();
  }

  tempGetUsers(){
    this.db.collection('user').valueChanges().subscribe(users => {
      console.log(users);
    })
  }

  filterList(field: string, direction: number){
    if(direction == 1){
      if(this.currentUser.direction == 1 && field == this.currentUser.field){
        this.currentUser = {field: "", direction: 0};
        this.getUsers();
      }else{
        this.currentUser = {field: field, direction: 1};
        this.users = this.db.collection('user', ref => ref.orderBy(field, "asc")).valueChanges();
      }
    }else{
      if(this.currentUser.direction == 2 && field == this.currentUser.field){
        this.currentUser = {field: "", direction: 0};
        this.getUsers();
      }else{
        this.currentUser = {field: field, direction: 2};
        this.users = this.db.collection('user', ref => ref.orderBy(field, "desc")).valueChanges();
      }
    }
  }

  filterSelected(field: string, direction: number){
    return this.currentUser.field == field && this.currentUser.direction == direction;
  }

  async deleteUser(userId: string){
    if(!await this.checkUserIDMath(userId)){
      this.authService.deleteUser(userId).then((res) => {
        res.subscribe((r2: any) => {
          if(r2.success){
            swal('Successfully Deleted', 'The user has successfully been deleted', 'success');
          }else{
            swal('Something Went Wrong', r2.message, 'error');
          }
        })
      })
    }else{
      swal('Delete Error', 'You cannot delete yourself!', 'error');
    }
  }

  async checkUserIDMath(userId: string): Promise<boolean>{
    return userId == (await this.afAuth.currentUser).uid;
  }

  resetPassword(userId: string){
    this.authService.resetPassword(userId).then((res) => res.subscribe((r2:any) => {
      if(r2.success){
        swal('Password reset', 'The new password is ' + r2.data + " Please email the password to the user.", "success")
      }else{
        console.log(r2.message)
        swal('Something Went Wrong', r2.message, 'error');
      }
    }))
  }

  editUser(userId: string){
    this.userService.id = userId;
    this.router.navigate(['users/edit']);
  }
}
