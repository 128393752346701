import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userId: string = null;

  constructor() { }

  get id(): string{
    return this.userId;
  }

  set id(id: string){
    this.userId = id;
  }

  async idSet(){
    return this.userId != null;
  }

  async resetUser(){
    this.userId = null;
  }
}
