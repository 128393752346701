<app-header></app-header>
<div class="content">
  <h1 class="overview-title">Open Inspections</h1>
  <div class="save-button" (click)="newInspection()" *ngIf="authService.checkRolesMult(['admin', 'inspector'])">
    Create New Inspection
  </div>
  <div class="table-container">
    <table class="table-case">
      <tr class="title-tr">
        <td>
          <i (click)="filterList('id', 1)"
            [ngClass]="{'selected': filterSelected('id', 1)}" class="fas fa-chevron-up up"></i>
          <span>IMO</span>
          <i (click)="filterList('id', 2)"
            [ngClass]="{'selected': filterSelected('id', 2)}" class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('imo', 1)"
            [ngClass]="{'selected': filterSelected('imo', 1)}" class="fas fa-chevron-up up"></i>
          <span>Vessel Name</span>
          <i (click)="filterList('imo', 2)"
            [ngClass]="{'selected': filterSelected('imo', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('date', 1)"
            [ngClass]="{'selected': filterSelected('date', 1)}" class="fas fa-chevron-up up"></i>
          <span>Date</span>
          <i (click)="filterList('cdate', 2)"
            [ngClass]="{'selected': filterSelected('date', 2)}" class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('portName', 1)"
            [ngClass]="{'selected': filterSelected('portName', 1)}" class="fas fa-chevron-up up"></i>
          <span>Location</span>
          <i (click)="filterList('portName', 2)"
            [ngClass]="{'selected': filterSelected('portName', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('status', 1)"
            [ngClass]="{'selected': filterSelected('status', 1)}" class="fas fa-chevron-up up"></i>
          <span>Inspection Status</span>
          <i (click)="filterList('status', 2)"
            [ngClass]="{'selected': filterSelected('status', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <span>View Inspection</span>
        </td>
      </tr>
      <tr *ngFor="let inspection of inspections | async" class="content-tr">
        <td>
          <span>{{inspection['imo']}}</span>
        </td>
        <td>
          <span>{{inspection['vesselName'].name ? inspection['vesselName'].name : inspection['vesselName']}}</span>
        </td>
        <td>
          <span>{{inspection['date'] | date}}</span>
        </td>
        <td>
          <span>{{inspection.portName + ", " + inspection['country']}}</span>
        </td>
        <td>
          <span>{{inspection['status']}}</span>
        </td>
        <td (click)="navigateToCase(inspection['id'])" class="icon-td">
          <div class="btn">
            <i class="far fa-file-alt"></i>
            <span>View</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
