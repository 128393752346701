import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isRadialGradient } from 'html2canvas/dist/types/css/types/image';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthServiceService, private router: Router){}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      const roles = next.data.roles as Array<string>
      let ret = await this.authService.checkRoleMultAsync(roles)
      if(ret){
        return ret;
      }else{
        this.router.navigate(['overview']);
        return false;
      }

  }
}
