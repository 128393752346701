import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../loading.service';
import swal from 'sweetalert';
import { HeaderService } from '../service/header.service';
import { AuthServiceService } from '../auth-service.service';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss']
})
export class PointComponent implements OnInit {

  vesselLength: number = 0;
  vesselType = null;
  vesselTypePic = null;
  starboard = true;
  strips = [];

  portSelectedStrips = [];
  starboardSelectedStrips = [];

  stripIcons = [];
  stripsId = [];
  currentStrip = 1;

  content = "details";
  stripDetail = [];

  inspectionId = null;
  inspection = null;
  vessel = null;

  transects = [];

  stripsDisabled = true;

  draftAtWaterline = null;
  editDraft: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private db: AngularFirestore,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    public authService: AuthServiceService,
    ) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "inspection";
    this.authService.getRole();
    this.strips = Array(32).fill(1,0,32).map((x,i)=>i);
    this.setupStripDetail();
    this.route.queryParams.subscribe(params => {
      if(params['id']){
        this.inspectionId = params['id'];
        console.log(this.inspectionId);
        this.db.collection('transect').ref.where('inspectionId', '==', params['id']).get().then(res => {
          if(!res.empty){
            res.forEach(elm => {
              this.transects.push(elm.data());
            })
            this.getStripSides();
            this.setupStripDetail();
          }
        })
        this.db.collection('inspection').doc(params['id']).get().subscribe(async inspection => {
          if(inspection.exists){
            if(await this.authService.checkRolesMult(['admin', 'inspector']) || (await this.authService.checkAccessPriv(inspection.data()['userAccess']))){
              this.inspection = inspection.data();
              if(this.inspection.draft){
                this.draftAtWaterline = this.inspection.draft;
              }
              this.db.collection('vessel').doc(this.inspection.imo.trim()).get().subscribe(res => {
                if(res.exists){
                  this.vessel = res.data();
                  this.db.collection('vesselType').doc(this.vessel.type.trim()).get().subscribe(resType => {
                    if(resType.exists){
                      this.vesselType = resType.get('name');
                      this.vesselTypePic = resType.get('pictureLink');
                      console.log(this.vesselTypePic)
                    }
                  })
                }else{
                  this.toastr.error('Vessel does not exist.', 'Vessel Error');
                }
              })
            }else{
              swal("Insufficient Privileges", "You do not have the required privileges to view this inspection. Please contact an admin if you think this in an error", "error").then(() => this.router.navigate(['overview']));
            }
          }
        })
        this.setupStripDetail();
      }else{
        swal("No Inspection Record", "This record does not exist. You will now be redirected to the new inspection point page.", "error").then(() => this.router.navigate(['overview']));
      }
    })
  }

  saveTransect(){
    this.starboardSelectedStrips.forEach(async elm => {
      let match = false;
      if(this.transects.length > 0){
        let temp = this.transects.find(x => x.stripId ==  elm)
        if(temp){
          if(temp.shipSide == "starboard"){
            match = true;
          }
        }
      }
      if(!match){
        let id = this.db.createId();
        let shipLocation = await this.getVesselSection("starboard", elm);
        this.db.collection('transect').doc(id).set({
          id: id,
          shipLocation: shipLocation,
          inspectionId: this.inspectionId,
          shipSide: "starboard",
          stripId: elm
        }).then(() => {
          window.location.reload();
        })
        this.stripIcons[elm + "s"] = {icon1: false, icon2: false, icon3: false, icon4: false}
      }
    })
    this.portSelectedStrips.forEach(async elm => {
      let match = false;
      if(this.transects.length > 0){
        let temp = this.transects.find(x => x.stripId ==  elm)
        if(temp){
          if(temp.shipSide == "port"){
            match = true;
          }
        }
      }
      if(!match){
        let id = this.db.createId();
        let shipLocation = await this.getVesselSection("port", elm);
        this.db.collection('transect').doc(id).set({
          id: id,
          shipLocation: shipLocation,
          inspectionId: this.inspectionId,
          shipSide: "port",
          stripId: elm
        }).then(() => {
          window.location.reload();
        })
        this.stripIcons[elm + "p"] = {icon1: false, icon2: false, icon3: false, icon4: false}
      }
    })
    this.stripsDisabled = true;
  }

  cancelAdd(){
    this.starboardSelectedStrips.forEach(async elm => {
      let match = false;
      if(this.transects.length > 0){
        let temp = this.transects.find(x => x.stripId ==  elm)
        if(temp){
          if(temp.shipSide == "starboard"){
            match = true;
          }
        }
      }
      if(!match){
        let index = this.starboardSelectedStrips.findIndex(x => x == elm);
        this.starboardSelectedStrips.splice(index, 1);
      }
    })
    this.portSelectedStrips.forEach(async elm => {
      let match = false;
      if(this.transects.length > 0){
        let temp = this.transects.find(x => x.stripId ==  elm)
        if(temp){
          if(temp.shipSide == "port"){
            match = true;
          }
        }
      }
      if(!match){
        let index = this.portSelectedStrips.findIndex(x => x == elm);
        this.portSelectedStrips.splice(index, 1);
      }
    })
    this.stripsDisabled = true;
  }

  getStripSides(){
    this.transects.forEach(element => {
      console.log("here");
      this.stripsId = element;
      if(element.shipSide == 'starboard'){
        this.starboardSelectedStrips.push(element.stripId);
        this.stripIcons[element.stripId + "s"] = {icon1: element.featureProp, icon2: element.featureBilge, icon3: element.featureChest, icon4: element.featureDraftmark}
      }else if(element.shipSide == 'port'){
        this.portSelectedStrips.push(element.stripId);
        this.stripIcons[element.stripId + "p"] = {icon1: element.featureProp, icon2: element.featureBilge, icon3: element.featureChest, icon4: element.featureDraftmark}
      }
    });
  }

  getStripIcons(side, icon, strip){
    try{
      if(icon == 1){
        return !this.stripIcons[strip + side]['featureProp'];
      }
      if(icon == 2){
        return !this.stripIcons[strip + side]['featureBilge'];
      }
      if(icon == 3){
        return !this.stripIcons[strip + side]['featureChest'];
      }
      if(icon == 4){
        return !this.stripIcons[strip + side]['featureDraftmark'];
      }
      return true;
    }catch(err){
      return false;
    }
  }

  inspectionStrip(strip, shipSide): void{
    //Move to video component
    //probably url will look like video/:id?strip=x&side=port
    if(this.draftAtWaterline){
      this.router.navigate(['detail', this.inspectionId], {queryParams: {strip: strip, side: shipSide}});
    }else{
      this.toastr.clear();
      this.toastr.warning('You must fill in the draft level at the waterline to move on.', 'No Draft Value');
    }
  }

  getStripDetails(strip, shipSide){
    return this.stripDetail[strip + shipSide];
  }

  setupStripDetail(){
    console.log("herr")
    this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });

    let strip = -1;
    let shipSide: string = null;

    //Go through each point for that transect get the information
    this.transects.forEach(transect => {
      shipSide = transect.shipSide;
      strip = transect.stripId;
      let stripName = strip+shipSide.trim().charAt(0);
      if(transect.featureProp){
        this.stripIcons[stripName]['featureProp'] = true;
      }
      if(transect.featureBilge){
        this.stripIcons[stripName]['featureBilge'] = true;
      }
      if(transect.featureChest){
        this.stripIcons[stripName]['featureChest'] = true;
      }
      if(transect.featureDraftmark){
        this.stripIcons[stripName]['featureDraftmark'] = true;
      }
      this.db.collection('point').ref.where('transectId', '==', transect.id).get().then((points: any) => {
        if(!points.empty){
          points.forEach(point => {
            let detailsPercentage =  point.data()['percentage']//(67*(point.data()['percentage']/100)) + 23;
            if(point.data().damage > 25){
              this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage});
            }else{
              this.stripDetail[stripName].push({'colour': point.data()['colour'], 'percentage': detailsPercentage});
            }
          })
        }
      })
    })
  }

  async getVesselSection(side: string, strip){
    //STARBOARD                 PORT
    //0-3 AFT                   28-31
    //4-19 MID                  12-27
    //20-25 SHOULDER            6-11
    //26-31 BOW                 0-5
    if(side == "starboard"){
      if(strip >= 0 && strip < 4){
        return "AFT";
      }else if(strip >= 4 && strip < 20){
        return "MIDSHIP";
      }else if (strip >= 20 && strip < 26){
        return "SHOULDER";
      }else{
        return "BOW";
      }
    }else{
      if(strip >= 0 && strip < 6){
        return "BOW";
      }else if(strip >= 6 && strip < 12){
        return "SHOULDER";
      }else if (strip >= 12 && strip < 28){
        return "MIDSHIP";
      }else{
        return "AFT";
      }
    }
  }

  changeStarboard(starboard: boolean): void{
    this.starboard = starboard;
  }

  stripSelected(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      this.starboardSelectedStrips.push(stripNumber);
    }
    if(shipSide === "port"){
      this.portSelectedStrips.push(stripNumber);
    }
  }

  stripDeleted(stripNumber: number, shipSide: string){
    if(shipSide == "starboard"){
      if(this.starboardSelectedStrips.indexOf(stripNumber) != -1){
        let match = false;
        let id = null;
        let temp = this.transects.find(x => x.stripId == stripNumber);
        if(temp){
          if(temp.shipSide == "starboard"){
            match = true;
            id = temp.id;
          }
        }

        if(match){
          this.db.collection('transect').doc(id).delete().then(() => {
            this.starboardSelectedStrips.splice(this.starboardSelectedStrips.indexOf(stripNumber), 1);
          })
        }else{
          this.starboardSelectedStrips.splice(this.starboardSelectedStrips.indexOf(stripNumber), 1);
        }
      }
    }
    if(shipSide == "port"){
      if(this.portSelectedStrips.indexOf(stripNumber) != -1){
        let match = false;
        let id = null;
        let temp = this.transects.find(x => x.stripId == stripNumber);
        if(temp){
          if(temp.shipSide == "port"){
            match = true;
            id = temp.id;
          }
        }

        if(match){
          this.db.collection('transect').doc(id).delete().then(() => {
            this.portSelectedStrips.splice(this.portSelectedStrips.indexOf(stripNumber), 1);
          })
        }else{
          this.portSelectedStrips.splice(this.portSelectedStrips.indexOf(stripNumber), 1);
        }
      }
    }
  }

  getStripVisible(strip, shipside){
    if(shipside == "starboard"){
      return this.starboardSelectedStrips.indexOf(strip) == -1
    }else {
      return this.portSelectedStrips.indexOf(strip) == -1
    }
  }

  saveDraftAtWaterline(){
    if(this.validateDraft()){
      this.db.collection('inspection').doc(this.inspectionId).update({
        draft: this.draftAtWaterline
      }).then(() => {this.toastr.success('Draft saved successfully', 'Save successful'); this.editDraft = false;})
    }
  }

  validateDraft(){
    if(isNaN(this.draftAtWaterline)){
      this.toastr.error('The draft value is not a number!', 'Value Error');
      return false;
    }else if(this.draftAtWaterline < 0){
      this.toastr.error('The draft value must be more than 1.', 'Value Error');
      return false;
    }else if(this.draftAtWaterline == 0){
      this.toastr.error('The draft value must be more than 1.', 'Value Error');
      return false;
    }else{
      return true;
    }
  }

  navigateInspection(){
    this.router.navigate(['inspection'], {queryParams: {id: this.inspectionId}})
  }
}
