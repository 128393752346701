<app-header></app-header>
<div class="content">
  <div class="profile-picture">
    <label class="upload-button" for="pictureUpload">
      <img src="{{photo}}">
    </label>
    <input (change)="uploadPhoto($event.target.files)" [hidden]="true"
      accept="image/jpeg, image/apng, image/avif, image/gif, image/png, image/svg+xml, image/webp" id="pictureUpload"
      type="file">
    <label class="save-button" for="pictureUpload">
      <span>Upload Profile Picture</span>
    </label>
  </div>
  <div class="details-container">
    <div class="top">
      <div class="section">
        <div class="row">
          <label for="displayName">Display name:</label>
          <input [(ngModel)]="displayName" id="displayName" placeholder="{{displayName}}" type="text">
        </div>
        <div class="row">
          <span (click)="changeDisplayName()" class="save-button">Save Name</span>
        </div>
      </div>
      <!-- .row -->
      <!-- %span.role Role: -->
      <!-- .role-text -->
      <!-- %span {{role}} -->
      <!-- %i.fas.fa-network-wired -->
    </div>
    <div class="bottom">
      <div class="section">
        <div class="row">
          <label for="email">Email:</label>
          <input [(ngModel)]="email" id="email" placeholder="{{email}}" type="text">
        </div>
        <div class="row">
          <label for="confirmEmail">Confirm Email:</label>
          <input [(ngModel)]="confirmEmail" id="confirmEmail" placeholder="{{confirmEmail}}" type="text">
        </div>
        <div class="row">
          <span (click)="changeEmail()" class="save-button">Update Email</span>
        </div>
      </div>
      <div class="section">
        <div class="row">
          <label for="oldPassword">Old Password:</label>
          <input [(ngModel)]="oldPassword" id="oldPassword" placeholder="{{oldPassword}}" type="password">
        </div>
        <div class="row">
          <label for="newPassword">New Password:</label>
          <input [(ngModel)]="newPassword" id="newPassword" placeholder="{{newPassword}}" type="password">
        </div>
        <div class="row">
          <label for="confirmPassword">Confirm Password:</label>
          <input [(ngModel)]="confirmPassword" id="confirmPassword" placeholder="{{confirmPassword}}" type="password">
        </div>
        <div class="row">
          <span (click)="changePassword()" class="save-button">Reset Password</span>
        </div>
      </div>
    </div>
  </div>
</div>
