<div class="content">
  <div class="title">
    <span>Add Inspection points</span>
    <i (click)="stripsDisabled = false" class="fas fa-plus-circle add"></i>
  </div>
  <div class="inspection-container">
    <div class="length-container">
      <div class="top">{{vesselLength}}m</div>
      <div class="bottom">&nbsp;</div>
    </div>
    <div class="ship-container">
      <img [hidden]="vesselType != 'ship01'" [ngClass]="{'port-view': !starboard}"
        class="selected-ship" src="./../../../assets/images/ships/01.png">
      <img [hidden]="vesselType != 'ship02'" [ngClass]="{'port-view': !starboard}"
        class="selected-ship" src="./../../../assets/images/ships/02.png">
      <img [hidden]="vesselType != 'ship03'" [ngClass]="{'port-view': !starboard}"
        class="selected-ship" src="./../../../assets/images/ships/03.png">
      <img [hidden]="vesselType != 'ship04'" [ngClass]="{'port-view': !starboard}"
        class="selected-ship" src="./../../../assets/images/ships/04.png">
      <img [hidden]="vesselType != 'ship05'" [ngClass]="{'port-view': !starboard}"
        class="selected-ship" src="./../../../assets/images/ships/05.png">
      <div class="ship-portion">
        <h3 [hidden]="stripsDisabled" class="prompt">CLICK ON VESSEL DIAGRAM TO PLACE INSPECTION POINT AT REQUIRED
          POSITION</h3>
        <div [ngClass]="{'hidden': !starboard}" class="starboard-breakdown">
          <div class="aft">
            <span>AFT</span>
            <div class="lower">&nbsp;</div>
          </div>
          <div class="midship">
            <span>MIDSHIP</span>
            <div class="lower">&nbsp;</div>
          </div>
          <div class="shoulder">
            <span>SHOULDER</span>
            <div class="lower">&nbsp;</div>
          </div>
          <div [ngClass]="{'right-ship': starboard}" class="bow">
            <span>BOW</span>
            <div class="lower">&nbsp;</div>
          </div>
        </div>
        <div [ngClass]="{'hidden': starboard}" class="port-breakdown">
          <div class="bow">
            <span>BOW</span>
            <div class="lower">&nbsp;</div>
          </div>
          <div class="shoulder">
            <span>SHOULDER</span>
            <div class="lower">&nbsp;</div>
          </div>
          <div class="midship">
            <span>MIDSHIP</span>
            <div class="lower">&nbsp;</div>
          </div>
          <div [ngClass]="{'right-ship': !starboard}" class="aft">
            <span>AFT</span>
            <div class="lower">&nbsp;</div>
          </div>
        </div>
      </div>
      <div [hidden]="!starboard" class="inspection-strip-container">
        <div *ngFor="let strip of strips"
          [ngClass]="{'disabled': stripsDisabled, 'hidden-strip': starboardSelectedStrips.indexOf(strip) == -1}"
          [ngStyle]="{&quot;left&quot;: strip*3 + &quot;%&quot;}" class="inspection-strip">
          <div (click)="stripSelected(strip, &quot;starboard&quot;)" class="number">{{strip+1}}</div>
          <div (click)="stripSelected(strip, &quot;starboard&quot;)" class="body"></div>
          <i (click)="stripDeleted(strip, &quot;starboard&quot;)" class="fas fa-trash-alt delete"></i>
        </div>
      </div>
      <div [hidden]="starboard" class="inspection-strip-container">
        <div *ngFor="let strip of strips"
          [ngClass]="{'disabled': stripsDisabled, 'hidden-strip': portSelectedStrips.indexOf(strip) == -1}"
          [ngStyle]="{&quot;left&quot;: strip*3 + &quot;%&quot;}" class="inspection-strip">
          <div (click)="stripSelected(strip, &quot;port&quot;)" class="number">{{strip+1}}</div>
          <div (click)="stripSelected(strip, &quot;port&quot;)" class="body"></div>
          <i (click)="stripDeleted(strip, &quot;port&quot;)" class="fas fa-trash-alt delete"></i>
        </div>
      </div>
    </div>
  </div>
</div>
