<div class="report-container" id="report-container">

    <div class="page-title">
        <h1 id="title">Executive Summary</h1>

        <div class="btns">
          <div class="save-btn hide-print" *ngIf="reportGenerated && authService.checkRolesMult(['admin', 'inspector'])" (click)="generateReport()">
              <span>Generate New Report</span>
          </div>
          <div class="print hide-print" (click)="print()" *ngIf="reportGenerated">
            <span>Print</span>
          </div>
        </div>
    </div>

    <div class="report-content-container" *ngIf="!reportGenerated">
        <mat-progress-bar mode="query" *ngIf="reportGenerating"></mat-progress-bar>
        <div class="save-btn" (click)="generateReport()" *ngIf="!reportGenerating && authService.checkRolesMult(['admin', 'inspector'])">
            <span>Generate Executive Summary</span>
        </div>
    </div>



    <div class="report-content-container" *ngIf="reportGenerated" id="report-contento-container">
        <div class="overview">
            <div class="fouling-rating">
                <div class="title">
                    <span>Overall Fouling Rating</span>
                </div>
                <div class="rating-container">
                    <div class="top"></div>
                    <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallHRating) + '% - 3.5px)'}"></div>
                </div>
            </div>
            <div class="info">
                <div class="section left">
                    <div class="details">
                        <div class="row">
                            <div class="title">Date:</div>
                            <div class="detail">{{inspection['date'] | date: 'medium'}}</div>
                        </div>
                        <div class="row">
                            <div class="title">Location:</div>
                            <div class="detail location">{{inspection.portName + ", " + inspection.country}}</div>
                        </div>
                        <div class="row">
                            <div class="title">ROV:</div>
                            <div class="detail">SeaDrone Pro</div>
                        </div>
                        <div class="row">
                            <div class="title">Operators:</div>
                            <div class="detail">{{inspectorString}}</div>
                        </div>
                    </div>
                </div>
                <div class="section right">
                    <div class="details details-picture">
                        <div class="row">
                            <div class="title">Sides:</div>
                            <div class="detail">{{coatingVert}}</div>
                        </div>
                        <div class="row">
                            <div class="title">Bottom:</div>
                            <div class="detail">{{coatingFlat}}</div>
                        </div>
                        <div class="row">
                            <div class="title">Sides:</div>
                            <div class="detail">{{coatingVert}}</div>
                        </div>
                        <div class="row">
                            <div class="title">Visibility:</div>
                            <div class="detail">{{visibility}}</div>
                        </div>
                        <div class="row">
                            <div class="title">Weather:</div>
                            <div class="detail">{{weatherString}}</div>
                        </div>
                    </div>
                    <div class="picture">
                        <img src="{{inspection.photoLink}}" alt="Picture of the vessel">
                    </div>
                </div>
            </div>
        </div>
        <div class="summary side-summary">
            <div class="collapser">
                <div class="title">
                    <span>Port Summary</span>
                </div>
                <div class="arrow">
                    <img src="../../assets/images/icons/noun-dropdown-331739.png" alt="Collapser"
                        [ngClass]="{'arrow-clicked': portClosed, 'arrow-not-clicked': !portClosed}"
                        (click)="portClosed = !portClosed">
                </div>
            </div>
            <div class="summary-content" *ngIf="!portClosed">
                <div class="fouling-ratings">
                    <div class="fouling-rating">
                        <div class="title">
                            <span>Port Fouling Rating</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallPortRating) + '% - 3.5px)'}">
                            </div>
                        </div>
                    </div>

                    <div class="fouling-rating sub-rating">
                        <div class="title">
                            <span>BOW</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(portBowRating) + '% - 3.5px)'}"></div>
                        </div>
                    </div>
                    <div class="fouling-rating sub-rating">
                        <div class="title">
                            <span>SHOULDER</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(portShoulderRating) + '% - 3.5px)'}">
                            </div>
                        </div>
                    </div>
                    <div class="fouling-rating sub-rating">
                        <div class="title">
                            <span>MIDSHIP</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(portMidshipRating) + '% - 3.5px)'}">
                            </div>
                        </div>
                    </div>
                    <div class="fouling-rating sub-rating">
                        <div class="title">
                            <span>AFT</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(portAftRating) + '% - 3.5px)'}"></div>
                        </div>
                    </div>
                    <div class="fouling-rating sub-rating">
                        <div class="title">
                            <span>WATERLINE</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(portWaterlineRating) + '% - 3.5px)'}">
                            </div>
                        </div>
                    </div>
                    <div class="fouling-rating sub-rating">
                        <div class="title">
                            <span>FLATS</span>
                        </div>
                        <div class="rating-container">
                            <div class="top"></div>
                            <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(portFlatsRating) + '% - 3.5px)'}"></div>
                        </div>
                    </div>
                </div>
                <div class="ship-overview">
                    <div class="length-container">
                        <div class="top">{{vessel.length}}m</div>
                        <div class="bottom">&nbsp;</div>
                    </div>
                    <div class="ship-container">
                        <img class="port-view" class="selected-ship" src="{{vesselTypePic}}">
                        <div class="ship-portion">
                            <div [ngClass]="{'hidden': starboard}" class="port-breakdown">
                                <div class="bow">
                                    <span>BOW</span>
                                    <div class="lower">&nbsp;</div>
                                </div>
                                <div class="shoulder">
                                    <span>SHOULDER</span>
                                    <div class="lower">&nbsp;</div>
                                </div>
                                <div class="midship">
                                    <span>MIDSHIP</span>
                                    <div class="lower">&nbsp;</div>
                                </div>
                                <div [ngClass]="{'right-ship': !starboard}" class="aft">
                                    <span>AFT</span>
                                    <div class="lower">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        <div class="inspection-strip-container">
                            <div *ngFor="let strip of strips"
                                [ngClass]="{'disabled': portSelectedStrips.indexOf(strip) == -1, 'hidden-strip': portSelectedStrips.indexOf(strip) == -1}"
                                [ngStyle]="{'left': strip*3 + '%'}" class="inspection-strip">
                                <div class="icon-container">
                                    <img class="icon" src="./../../../assets/images/vessel-features/1.png"
                                        *ngIf="!getStripIcons( 'p', 1, strip)">
                                    <img class="icon" src="./../../../assets/images/vessel-features/2.png"
                                        *ngIf="!getStripIcons( 'p', 2, strip)">
                                    <img [ngClass]="{'icon-hidden': getStripIcons( 'p', 3, strip)}" class="icon"
                                        src="./../../../assets/images/vessel-features/3.png"
                                        *ngIf="!getStripIcons( 'p', 3, strip)">
                                    <img [ngClass]="{'icon-hidden': getStripIcons( 'p', 4, strip)}" class="icon"
                                        src="./../../../assets/images/vessel-features/4.png"
                                        *ngIf="!getStripIcons( 'p', 4, strip)">
                                </div>
                                <div class="number">{{strip+1}}</div>
                                <div class="body">
                                    <div *ngFor="let detail of getStripDetails(strip, 'p')"
                                        [ngStyle]="{'top': detail.percentage+ '%', 'background-color': detail.colour}"
                                        class="detail">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summary side-summary">
                <div class="collapser">
                    <div class="title">
                        <span>Starboard Summary</span>
                    </div>
                    <div class="arrow">
                        <img src="../../assets/images/icons/noun-dropdown-331739.png" alt="Collapser"
                            [ngClass]="{'arrow-clicked': starClosed, 'arrow-not-clicked': !starClosed}"
                            (click)="starClosed = !starClosed">
                    </div>
                </div>

                <div class="summary-content" *ngIf="!starClosed">
                    <div class="fouling-ratings">
                        <div class="fouling-rating">
                            <div class="title">
                                <span>Starboard Fouling Rating</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer"
                                    [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallStarboardRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>

                        <div class="fouling-rating sub-rating">
                            <div class="title">
                                <span>BOW</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(starBowRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>
                        <div class="fouling-rating sub-rating">
                            <div class="title">
                                <span>SHOULDER</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(starShoulderRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>
                        <div class="fouling-rating sub-rating">
                            <div class="title">
                                <span>MIDSHIP</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(starMidshipRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>
                        <div class="fouling-rating sub-rating">
                            <div class="title">
                                <span>AFT</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(starAftRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>
                        <div class="fouling-rating sub-rating">
                            <div class="title">
                                <span>WATERLINE</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer"
                                    [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(starWaterlineRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>
                        <div class="fouling-rating sub-rating">
                            <div class="title">
                                <span>FLATS</span>
                            </div>
                            <div class="rating-container">
                                <div class="top"></div>
                                <div class="pointer" [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(starFlatsRating) + '% - 3.5px)'}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ship-overview">
                        <div class="length-container">
                            <div class="top">{{vessel.length}}m</div>
                            <div class="bottom">&nbsp;</div>
                        </div>
                        <div class="ship-container">
                            <img class="selected-ship" src="{{vesselTypePic}}">
                            <div class="ship-portion">
                                <div class="starboard-breakdown">
                                    <div class="aft">
                                        <span>AFT</span>
                                        <div class="lower">&nbsp;</div>
                                    </div>
                                    <div class="midship">
                                        <span>MIDSHIP</span>
                                        <div class="lower">&nbsp;</div>
                                    </div>
                                    <div class="shoulder">
                                        <span>SHOULDER</span>
                                        <div class="lower">&nbsp;</div>
                                    </div>
                                    <div class="bow right-ship">
                                        <span>BOW</span>
                                        <div class="lower">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="inspection-strip-container">
                                    <div *ngFor="let strip of strips"
                                        [ngClass]="{'disabled': starboardSelectedStrips.indexOf(strip) == -1, 'hidden-strip': starboardSelectedStrips.indexOf(strip) == -1}"
                                        [ngStyle]="{'left': strip*3 + '%'}" class="inspection-strip">
                                        <div class="icon-container">
                                            <img class="icon" src="./../../../assets/images/vessel-features/1.png"
                                                *ngIf="!getStripIcons( 's', 1, strip)">
                                            <img class="icon" src="./../../../assets/images/vessel-features/2.png"
                                                *ngIf="!getStripIcons( 's', 2, strip)">
                                            <img [ngClass]="{'icon-hidden': getStripIcons( 'p', 3, strip)}" class="icon"
                                                src="./../../../assets/images/vessel-features/3.png"
                                                *ngIf="!getStripIcons( 's', 3, strip)">
                                            <img [ngClass]="{'icon-hidden': getStripIcons( 'p', 4, strip)}" class="icon"
                                                src="./../../../assets/images/vessel-features/4.png"
                                                *ngIf="!getStripIcons( 's', 4, strip)">
                                        </div>
                                        <div class="number">{{strip+1}}</div>
                                        <div class="body">
                                            <div *ngFor="let detail of getStripDetails(strip, 's')"
                                                [ngStyle]="{'top': detail.percentage+ '%', 'background-color': detail.colour}"
                                                class="detail">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary vessel-summary">
                    <div class="collapser">
                        <div class="title">
                            <span>Vessel Summary</span>
                        </div>
                        <div class="arrow">
                            <img src="../../assets/images/icons/noun-dropdown-331739.png" alt="Collapser"
                                [ngClass]="{'arrow-clicked': vesselMainClosed, 'arrow-not-clicked': !vesselMainClosed}"
                                (click)="vesselMainClosed = !vesselMainClosed">
                        </div>
                    </div>

                    <div class="section" *ngIf="!vesselMainClosed">
                        <div class="left">
                            <div class="fouling-rating">
                                <div class="title">
                                    <span>Bow</span>
                                </div>
                                <div class="rating-container">
                                    <div class="top"></div>
                                    <div class="pointer"
                                        [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallBowRating) + '% - 3.5px)'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="bowComments" id="bowComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="bowComments"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="section" *ngIf="!vesselMainClosed">
                        <div class="left">
                            <div class="fouling-rating">
                                <div class="title">
                                    <span>Shoulder</span>
                                </div>
                                <div class="rating-container">
                                    <div class="top"></div>
                                    <div class="pointer"
                                        [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallShoulderRating) + '% - 3.5px)'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="shouldComments" id="shouldComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="shoulderComments"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="section" *ngIf="!vesselMainClosed">
                        <div class="left">
                            <div class="fouling-rating">
                                <div class="title">
                                    <span>Midship</span>
                                </div>
                                <div class="rating-container">
                                    <div class="top"></div>
                                    <div class="pointer"
                                        [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallMidshipRating) + '% - 3.5px)'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="midComments" id="midComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="midshipComments"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="section" *ngIf="!vesselMainClosed">
                        <div class="left">
                            <div class="fouling-rating">
                                <div class="title">
                                    <span>AFT</span>
                                </div>
                                <div class="rating-container">
                                    <div class="top"></div>
                                    <div class="pointer"
                                        [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallAftRating) + '% - 3.5px)'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="aftComments" id="aftComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="aftComments"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary vessel-summary" id="propRud">
                    <div class="collapser">
                        <div class="title">
                            <span>Propeller & Rudder</span>
                        </div>
                        <div class="arrow">
                            <img src="../../assets/images/icons/noun-dropdown-331739.png" alt="Collapser"
                                [ngClass]="{'arrow-clicked': propRudClosed, 'arrow-not-clicked': !propRudClosed}"
                                (click)="propRudClosed = !propRudClosed">
                        </div>
                    </div>
                    <div class="section" *ngIf="!propRudClosed">
                        <div class="left">
                            <div class="title-other">
                                <span>Propeller</span>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="propComments" id="propComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="propComments"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="section" *ngIf="!propRudClosed">
                        <div class="left">
                            <div class="title-other">
                                <span>Rudder</span>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="rudderComments" id="rudderComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="rudComments"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary vessel-summary">
                    <div class="collapser">
                        <div class="title">
                            <span>Waterline & Flats</span>
                        </div>
                        <div class="arrow">
                            <img src="../../assets/images/icons/noun-dropdown-331739.png" alt="Collapser"
                                [ngClass]="{'arrow-clicked': waterFlatClosed, 'arrow-not-clicked': !waterFlatClosed}"
                                (click)="waterFlatClosed = !waterFlatClosed">
                        </div>
                    </div>
                    <div class="section" *ngIf="!waterFlatClosed">
                        <div class="left">
                            <div class="fouling-rating">
                                <div class="title">
                                    <span>Waterline</span>
                                </div>
                                <div class="rating-container">
                                    <div class="top"></div>
                                    <div class="pointer"
                                        [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallWaterlineRating) + '% - 3.5px)'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="waterComments" id="waterComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="waterlineComments"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="section" *ngIf="!waterFlatClosed">
                        <div class="left">
                            <div class="fouling-rating">
                                <div class="title">
                                    <span>Flats</span>
                                </div>
                                <div class="rating-container">
                                    <div class="top"></div>
                                    <div class="pointer"
                                        [ngStyle]="{'left': 'calc( ' + getFoulingPercentage(overallFlatsRating) + '% - 3.5px)'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <span>Comments</span>
                            </div>
                            <div class="bottom">
                                <textarea name="flatsComments" id="flatsComments" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="flatsComments"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary actions">
                    <div class="collapser">
                        <div class="title">
                            <span>Recommended Actions</span>
                        </div>
                        <div class="arrow">
                            <img src="../../assets/images/icons/noun-dropdown-331739.png" alt="Collapser"
                                [ngClass]="{'arrow-clicked': recClosed, 'arrow-not-clicked': !recClosed}"
                                (click)="recClosed = !recClosed">
                        </div>
                    </div>
                    <div class="recommended" *ngIf="!recClosed">
                        <textarea name="actions" id="actions" maxlength="250" placeholder="Type some notes here..." [(ngModel)]="actions"></textarea>
                    </div>
                </div>
                <div class="bottom-btns">
                    <div class="interactive btn hide-print" (click)="navigateTransects()">
                        <span>Interactive Report</span>
                    </div>

                    <div class="save hide-print" (click)="saveUpdatedReport()">
                        <span>Save Report</span>
                    </div>
                </div>

                <div class="hidden-div"></div>
            </div>

        </div>
