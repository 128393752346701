import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from '@firebase/util';
import { ToastrService } from 'ngx-toastr';
import {map} from 'rxjs';
import swal from 'sweetalert';

@Component({
  selector: 'app-attach-user',
  templateUrl: './attach-user.component.html',
  styleUrls: ['./attach-user.component.scss']
})
export class AttachUserComponent implements OnInit {

  errorCodes = {
    1: "The data is more up to date elsewhwere. Updating the data now.",
    2: "Something went wrong or the inspection may no longer exist.",
    3: "The user already has access to this inspection.",
    4: "The user already does not have access to this inspection."
  }

  @Input()
  inspection;

  originalUserAccess = [];

  users = null;

  currentFilter = {
    direction: 0,
    field: ""
  }

  searchText: string = null;

  constructor(private db: AngularFirestore,
    private router: Router) { }

  ngOnInit(): void {
    this.getUsers();
    this.originalUserAccess = this.inspection.userAccess.slice();
  }

  async addUserAccessToArray(userId: string){
    if(this.inspection.userAccess.findIndex(x => x == userId) == -1){
      this.inspection.userAccess.push(userId);
    }else{
      swal('Access Already Granted', this.errorCodes[3], 'error');
    }
  }

  async removeUserAccessFromArray(userId: string, role: string){
    if(role == "client"){
      if(this.inspection.userAccess.findIndex(x => x == userId) != -1){
        this.inspection.userAccess.splice(this.inspection.userAccess.findIndex(x => x == userId), 1);
      }else{
        swal('Access Already Revoked', this.errorCodes[4], 'error');
      }
    }else{
      swal("Can't Remove Access", "User is an " + role + " and therefore their access cannot be revoked.", 'error')
    }
  }

  async saveUserAccess(){
    (await this.persistanceCheck()).subscribe(res => {
      if(res == 0){
        this.db.collection('inspection').doc(this.inspection.id).update({
          userAccess: this.inspection.userAccess
        }).then(() => {swal('Access Details Saved', 'The User Access alterations have been saved.', 'success')})
        .catch((e) => {swal('Something Went Wrong', 'Please try again', 'error'); console.log(e)});
      }else if (res == 2){
        swal('Something went wrong', this.errorCodes[2], 'error').then(() => this.router.navigate(['overview']))
      }else{
        swal('Something went wrong', this.errorCodes[res], 'error');
      }
    })
  }

  async persistanceCheck(){
    return this.db.collection('inspection').doc(this.inspection.id).get().pipe(map((ins: any) => {
      if(ins.exists){
        if(JSON.stringify(ins.data().userAccess) === JSON.stringify(this.originalUserAccess)){
          return 0;
        }else{
          this.inspection = ins.data();
          return 1;
        }
      }else{
        return 2;
      }
    }))
  }

  filterList(field: string, direction: number){
    if(direction == 1){
      if(this.currentFilter.direction == 1 && field == this.currentFilter.field){
        this.currentFilter = {field: "", direction: 0};
        this.getUsers();
      }else{
        this.currentFilter = {field: field, direction: 1};
        this.users = this.db.collection('user', ref => ref.orderBy(field, "asc")).valueChanges();
      }
    }else{
      if(this.currentFilter.direction == 2 && field == this.currentFilter.field){
        this.currentFilter = {field: "", direction: 0};
        this.getUsers();
      }else{
        this.currentFilter = {field: field, direction: 2};
        this.users = this.db.collection('user', ref => ref.orderBy(field, "desc")).valueChanges();
      }
    }
  }

  filterSelected(field: string, direction: number){
    return this.currentFilter.field == field && this.currentFilter.direction == direction;
  }

  getUsers(){
    this.users = this.db.collection('user').valueChanges();
  }

  //Returns true if user already has access
  userHasAccess(userId: string, role: string): boolean{
    return (this.inspection.userAccess.findIndex(x => x == userId) != -1 && role == "client") || role == "admin" || role == "inspectors";
  }
}
