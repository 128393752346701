<app-header></app-header>
<div class="content">
  <div class="title-container">
    <h1>New User</h1>
    <div class="buttons">
      <div (click)="saveUser()" [ngClass]="{'can-submit': !canSubmit()}" class="save-button">
        <span>Save User</span>
      </div>
      <div class="back-button" [routerLink]="['/users']" (click)="userService.resetUser()">
        Back
      </div>
    </div>
  </div>
  <div class="vessel-input">
    <div class="vessel-details">
      <div class="row top">
        <label for="name">Name:</label>
        <input type="text" name="name" id="name" required [(ngModel)]="name">
      </div>
      <div class="row">
        <label for="email">Email:</label>
        <input type="text" name="email" id="email" required [(ngModel)]="email">
      </div>
      <div class="row">
        <label for="company">Company:</label>
        <input type="text" name="company" id="company" list="companies" required [(ngModel)]="company">
        <datalist name="companies" id="companies" [(ngModel)]="company">
          <option *ngFor="let cust of customers" value="{{cust.name}}"></option>
        </datalist>
      </div>
      <div class="row">
        <label for="role">System Role:</label>
        <input [(ngModel)]="role" id="role" list="roles" (change)="selectRole(role)">
        <datalist name="roles" id="roles" [(ngModel)]="role">
          <option *ngFor="let r of roles" value="{{r}}"></option>
        </datalist>
      </div>
    </div>
  </div>
</div>
