// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {

    /*
      apiKey: "AIzaSyB_0ZUM9ZWw5znwcQhmDpyc6_Y4ChaaL4o",
      authDomain: "akzonobel-vis.firebaseapp.com",
      databaseURL: "https://akzonobel-vis.firebaseio.com",
      projectId: "akzonobel-vis",
      storageBucket: "akzonobel-vis.appspot.com",
      messagingSenderId: "105010104133",
      appId: "1:105010104133:web:4e56d24a1cb558fb9b202c",
      measurementId: "G-0X2J995MPC"
      */

      apiKey: "AIzaSyDBl8_E4oZMgjbvWb_eYv-B91FW1w8mAqo",
      authDomain: "akzonobelphase2.firebaseapp.com",
      databaseURL: "https://akzonobelphase2-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "akzonobelphase2",
      storageBucket: "akzonobelphase2.appspot.com",
      messagingSenderId: "360762273404",
      appId: "1:360762273404:web:8a8e623668de8b772ae3f9",
      measurementId: "G-9XL5N8MX9Y"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
