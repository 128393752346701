<app-header></app-header>
<div class="content">
  <div class="title-container">
    <h1 *ngIf="vesselId == null">New Vessel</h1>
    <h1 *ngIf="vesselId != null">Vessel Details</h1>
    <div class="buttons">
      <div (click)="saveVessel()" [ngClass]="{'can-submit': !canSubmit()}" class="save-button">
        <span>Save Details</span>
      </div>
      <div class="back-button" (click)="overview()">
        Back
      </div>
    </div>
  </div>
  <div class="vessel-input">
    <div class="picture">
      <label class="picture-upload-label" for="pictureUpload">
        <img src="{{photo}}">
      </label>
      <input (change)="uploadPhoto($event.target.files)" [hidden]="true"
        accept="image/jpeg, image/apng, image/avif, image/gif, image/png, image/svg+xml, image/webp" id="pictureUpload"
        type="file">
      <label for="pictureUpload" class="save-button">
        <span>Upload Vessel Image</span>
      </label>
    </div>
    <div class="vessel-details">
      <div class="row top">
        <label for="imo">IMO:</label>
        <input [(ngModel)]="imo" id="imo" placeholder="IMO" required="" type="text">
      </div>
      <div class="row">
        <label for="vesselName">Vessel Name:</label>
        <input [(ngModel)]="vesselName" id="vesselName" placeholder="Vessel Name" required="" type="text">
      </div>
      <div class="row">
        <label for="vesselLength">Vessel Length (m):</label>
        <input type="text" [(ngModel)]="vesselLength" id="vesselLength" placeholder="Vessel Length" required="">
      </div>
      <div class="row">
        <label for="vesselType">Vessel Type:</label>
        <select name="vesselType" id="vesselType" [(ngModel)]='vesselType'>
          <option *ngFor="let type of vesselTypes" value="{{type.id}}">{{type.name}}</option>
        </select>
      </div>
      <div class="row">
        <label for="customerSelected">Customer:</label>
        <input list="customer" id="customerSelected" [(ngModel)]="customerSelected" (change)="selectCustomer(customerSelected)" placeholder="Type to search a customer..."/>
          <datalist name="customer" id="customer" [(ngModel)]="customerSelected" >
            <option *ngFor="let customer of customers" value="{{customer.name}}"></option>
          </datalist>
      </div>
    </div>
  </div>
</div>
