import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { dir } from 'console';
import { AuthServiceService } from '../auth-service.service';
import { LoadingService } from '../loading.service';
import { HeaderService } from '../service/header.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor(private loadingService: LoadingService,
    private router: Router,
    private db: AngularFirestore,
    private headerService: HeaderService,
    public authService: AuthServiceService,
    private afAuth: AngularFireAuth) { }

  loadingScreen 	 = false;

  inspections          = null;

  currentFilter    = {field: "", direction: 0};

  ngOnInit(): void {
    this.headerService.CurrentPage = "inspection";
    this.loadingService.setLoadingScreen(false);
    this.getCases();
    this.authService.getRole();
  }


  navigateToCase(id: string): void{
    this.router.navigate(['/inspection'], {queryParams: {id: id}});
  }

  async getCases(){
    if(await this.authService.getUserRole() == "admin" || await this.authService.getUserRole() == "inspector"){
      this.inspections = this.db.collection('inspection').valueChanges();
    }else{
      let uid = (await this.afAuth.currentUser).uid;
      this.inspections = this.db.collection('inspection', ref => ref.where('userAccess', 'array-contains', uid)).valueChanges();
    }
  }

  filterList(field: string, direction: number){
    if(direction == 1){
      if(this.currentFilter.direction == 1 && field == this.currentFilter.field){
        this.currentFilter = {field: "", direction: 0};
        this.getCases();
      }else{
        this.currentFilter = {field: field, direction: 1};
        this.getCasesByField(field, direction);
      }
    }else{
      if(this.currentFilter.direction == 2 && field == this.currentFilter.field){
        this.currentFilter = {field: "", direction: 0};
        this.getCases();
      }else{
        this.currentFilter = {field: field, direction: 2};
        this.getCasesByField(field, direction);
      }
    }
  }

  async getCasesByField(field: string, direction: number){
    if(await this.authService.getUserRole() == "admin" || await this.authService.getUserRole() == "inspector"){
      if(direction == 1){
        this.inspections = this.db.collection('inspection', ref => ref.orderBy(field, "asc")).valueChanges();
      }else{
        this.inspections = this.db.collection('inspection', ref => ref.orderBy(field, "desc")).valueChanges();
      }
    }else{
      let uid = (await this.afAuth.currentUser).uid;
      console.log(uid)
      if(direction == 1){
        this.inspections = this.db.collection('inspection', ref => ref.where('userAccess', 'array-contains', uid).orderBy(field, "asc")).valueChanges();
      }else{
        this.inspections = this.db.collection('inspection', ref => ref.where('userAccess', 'array-contains', uid).orderBy(field, "desc")).valueChanges();
      }
    }
  }

  filterSelected(field: string, direction: number){
    return this.currentFilter.field == field && this.currentFilter.direction == direction;
  }

  newInspection(){
    this.router.navigate(['inspection/new'])
  }
}
