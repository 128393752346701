import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import swal from 'sweetalert';
import { AuthServiceService } from '../auth-service.service';
import { InspectionNavService } from '../inspection-nav.service';
import { HeaderService } from '../service/header.service';
import { VimeoUploadService } from '../vimeo-upload.service';
import { VimeoService } from '../vimeo.service';


@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss']
})
export class CaseComponent implements OnInit {

  vessel              = null;
  vesselDb            = null;
  id                  = null;

  content             = "details";
  activeButton        = "btn1"

  vesselName          = "Costa Luminosa";
  port                = "Palermo";
  caseDate            = "October 2019";

  vesselLength        = 160;
  vesselType          = "ship02";

  step = 3;

  starboard           = true;

  cleaningReports     = {"reports" : [{"title" : "01 Jan 2019", "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"}]}
  coatingReports      = {"reports" : [{"title" : "22 Sept 2017", "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"}, {"title" : "06 June 2011", "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"}]};

  strips              = [];
  levelDepth          = [];

  portSelectedStrips       = [];
  starboardSelectedStrips  = [];

  stripsId            = [];

  currentStrip        = 1;

  videoUploadOpen          = false;

  fileToUpload: File       = null;

  videoLink                = this.vimeoUpload.getVideoLink();

  mouseDown            = false;

  screenShotActive      = false;

  currentScreenshotId  = null;
  currentFoulingId      = null;

  screenShotId        = [];

  foulingColours      = [];

  screenshots         = [];

  stripDetail         = [];
  stripsDisabled       = true;

  photo               = "./../../../assets/images/vessel-photo.png";

  damaged             = false;
  damagedMarkers      = [];

  selectedIcons       = [];

  caseStatus = null;

  vesselReport: SafeResourceUrl = null;

  stripIcons = [];

  draftInput  = 0;
  depthInput = 0;

  draftNumbers = [];
  depthNumbers = [];

  screenshotToast: number;

  disableScreenshot: boolean = false;

  inspection = null;
  inspectionReport = null;

  private element: HTMLElement;
  foulingRatings        = [0, 0, 0, 0];

  coatings = [];
  inspectors = [];
  ports = [];
  visibilities = [];

  tempPortName = null;
  tempCoatingVert = null;
  tempCoatingFlat = null;
  tempCoatingBoot = null;
  tempInspector = null;
  tempVisibility = null;

  inspectorString = null;
  weathers = [];
  weatherString = null;

  editEnabled: boolean = false;

  constructor(private route: ActivatedRoute,
    private db: AngularFirestore,
    public vimeo: VimeoService,
    public vimeoUpload: VimeoUploadService,
    public authService: AuthServiceService,
    private afStorage: AngularFireStorage,
    private sanitiser: DomSanitizer,
    private router: Router,
    private toastr: ToastrService,
    private headerService: HeaderService,
    @Inject(DOCUMENT) private document: any,
    private inspecNav: InspectionNavService) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "inspection";
    this.authService.getRole();
    let sub = this.route.queryParams.subscribe(params => {
      this.id = params['id'] || 0;
      this.db.collection('inspection').doc(this.id).get().subscribe(async data => {
        if(data.exists){
          if(await this.authService.checkRoleMultAsync(['admin', 'inspector']) || (await this.authService.checkAccessPriv(data.data()['userAccess']))){
            this.inspection = data.data();
            this.caseStatus = this.inspection.status;
            this.inspectionReport = this.inspection['reportPdf'] ? this.sanitiser.bypassSecurityTrustResourceUrl(this.inspection['reportPdf']) : null;
            if(this.inspection.photoLink){
              this.photo = this.inspection.photoLink;
            }
            this.tempPortName = this.inspection['portName'];
            if(this.inspection['reportPdf']){
              this.vesselReport = this.sanitiser.bypassSecurityTrustResourceUrl(this.inspection['reportPdf'])
            }
            this.db.collection('vessel').doc(this.inspection.imo.trim()).get().subscribe(vessel => {
              if(vessel.exists){
                this.vessel = vessel.data();
              }
            })
            this.db.collection('coating').get().subscribe(coatings => {
              if(!coatings.empty){
                coatings.forEach(coating => {
                  this.coatings.push(coating.data());
                })
                this.tempCoatingVert = this.coatings.find(x => x.id == this.inspection.coatingVert).coating;
                this.tempCoatingFlat = this.coatings.find(x => x.id == this.inspection.coatingFlat).coating;
                this.tempCoatingBoot = this.coatings.find(x => x.id == this.inspection.coatingBoot).coating;
              }
            })
            this.db.collection('waterVisibility').get().subscribe(visibilities => {
              if(!visibilities.empty){
                visibilities.forEach(visibility => {
                  this.visibilities.push(visibility.data());
                })
                this.tempVisibility = this.visibilities.find(x => x.id == this.inspection.visibility).visibility;
              }
            })

            this.inspection.inspectors.forEach(inspectorId => {
              this.db.collection('inspector').doc(inspectorId).get().subscribe((ins: any) => {
                if (ins.exists) {
                  let insArray: Array<string> = ins.data().name.split(" ");
                  console.log(insArray);
                  insArray[0] = insArray[0].charAt(0).toUpperCase();
                  let insp = insArray.join(" ");
                  this.inspectors.push(insp);
                  if (this.inspectorString == null) {
                    if (this.inspection.inspectors.length > 1) {
                      this.inspectorString = insp + " & ";
                    } else {
                      this.inspectorString = insp;
                    }
                  } else {
                    let index = this.inspection.inspectors.findIndex(x => x == inspectorId);
                    if ((this.inspection.inspectors.length - 1) == index) {
                      this.inspectorString = this.inspectorString + insp;
                    } else {
                      this.inspectorString = this.inspectorString + insp + " & ";
                    }
                  }
                }
              })
            });

            this.inspection.weathers.forEach(weatherId => {
              this.db.collection('weather').doc(weatherId).get().subscribe((weath: any) => {
                if (weath.exists) {
                  let weathe = weath.data();
                  this.weathers.push(weath.data());
                  if (this.weatherString == null) {
                    if (this.inspection.weathers.length > 1) {
                      this.weatherString = weathe.weather + " & ";
                    } else {
                      this.weatherString = weathe.weather;
                    }
                  } else {
                    let index = this.inspection.weathers.findIndex(x => x == weatherId);
                    if ((this.inspection.weathers.length - 1) == index) {
                      this.weatherString = this.weatherString + weathe.weather;
                    } else {
                      this.weatherString = this.weatherString + weathe.weather + " & ";
                    }
                  }
                }
              })
            });
          }else{
            swal("Insufficient Privileges", "You do not have the required privileges to view this inspection. Please contact an admin if you think this in an error", "error").then(() => this.router.navigate(['overview']));
          }

        }else{
          swal("Data Error", "The inspection does not exist.You will now be redirected to the new inspection point page.", "error").then(() => this.router.navigate(['overview']));
        }
      })
    })
  }

  updateInspectionStatus(newValue){
    this.caseStatus = newValue;
    this.db.collection('inspection').doc(this.id).update({status: this.caseStatus});
  }

  updatePort(newValue){
    this.tempPortName = newValue;
    let tempPort = this.ports.find(x => x.name == this.tempPortName).id;
    this.db.collection('inspection').doc(this.id).update({port: tempPort, portName: this.tempPortName});
  }

  updateVisibility(newValue){
    this.tempVisibility = newValue;
    let tempVis = this.visibilities.find(x => x.visibilities == this.tempVisibility).id;
    this.db.collection('inspection').doc(this.id).update({visibility: tempVis});
  }

  updateInspectors(newValue){
    this.tempInspector = newValue;
    let tempInspec = this.inspectors.find(x => x.name == this.tempInspector).id;
    this.db.collection('inspection').doc(this.id).update({inspector: tempInspec});
  }

  updateCoatingFlat(newValue){
    this.tempCoatingFlat = newValue;
    let tempFlat = this.coatings.find(x => x.coating == this.tempCoatingFlat).id;
    this.db.collection('inspection').doc(this.id).update({coatingFlat: tempFlat});
  }

  updateCoatingVert(newValue){
    this.tempCoatingVert = newValue;
    let tempVert = this.coatings.find(x => x.coating == this.tempCoatingVert).id;
    this.db.collection('inspection').doc(this.id).update({coatingVert: tempVert});
  }

  updateCoatingBoot(newValue){
    this.tempCoatingBoot = newValue;
    let tempVert = this.coatings.find(x => x.coating == this.tempCoatingBoot).id;
    this.db.collection('inspection').doc(this.id).update({coatingBoot: tempVert});
  }

  overview(){
    this.router.navigate(['overview']);
  }

  transects(){
    this.router.navigate(['transect'], {queryParams: {id: this.id}});
  }

  setupStripDetail(){
    this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });

    let strip = -1;
    let shipSide = null;
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').get().subscribe(data => {
      if(data.docs){
        data.docs.forEach(elm => {

          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(elm.data()['id']).collection('screenshot').get().subscribe(screenshot => {
            if(screenshot.docs){
              screenshot.docs.forEach(screenshotElmt => {
                this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(elm.data()['id']).collection('screenshot').doc(screenshotElmt.data()['id']).collection('fouling').get().subscribe(val => {
                  if(val.docs){
                    val.docs.forEach(foulingVl => {
                      shipSide = elm.data()['shipside'];
                      strip = elm.data()['strip'];
                      if(shipSide == "starboard"){
                        let icon1 = false;
                        let icon2 = false;
                        let icon3 = false;
                        let icon4 = false;
                        let stripName = strip+"s";
                        let detailsPercentage = (67*(foulingVl.data()['percentage']/100)) + 23;


                        if(foulingVl.data()['icon1']){
                          icon1 = true;
                        }
                        if(foulingVl.data()['icon2']){
                          icon2 = true;
                        }
                        if(foulingVl.data()['icon3']){
                          icon3 = true;
                        }
                        if(foulingVl.data()['icon4']){
                          icon4 = true;
                        }

                        if(!this.stripIcons[stripName]['icon1'] && icon1){
                          this.stripIcons[stripName]['icon1'] = icon1;
                        }

                        if(!this.stripIcons[stripName]['icon2'] && icon2){
                          this.stripIcons[stripName]['icon2'] = icon2;
                        }
                        if(!this.stripIcons[stripName]['icon3'] && icon3){
                          this.stripIcons[stripName]['icon3'] = icon3;
                        }
                        if(!this.stripIcons[stripName]['icon4'] && icon4){
                          this.stripIcons[stripName]['icon4'] = icon4;
                        }
                        if(foulingVl.data()['damaged']){
                          this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage, 'icon1': foulingVl.data()['icon1'], 'icon2': foulingVl.data()['icon2'], 'icon3': foulingVl.data()['icon3'], 'icon4': foulingVl.data()['icon4']});
                        }else{
                          this.stripDetail[stripName].push({'colour': foulingVl.data()['colour'], 'percentage': detailsPercentage, 'icon1': foulingVl.data()['icon1'], 'icon2': foulingVl.data()['icon2'], 'icon3': foulingVl.data()['icon3'], 'icon4': foulingVl.data()['icon4']});
                        }
                      }else{
                        let icon1 = false;
                        let icon2 = false;
                        let icon3 = false;
                        let icon4 = false;
                        let stripName = strip+"p";
                        let detailsPercentage = (67*(foulingVl.data()['percentage']/100)) + 23;

                        if(foulingVl.data()['icon1']){
                          icon1 = true;
                        }
                        if(foulingVl.data()['icon2']){
                          icon2 = true;
                        }
                        if(foulingVl.data()['icon3']){
                          icon3 = true;
                        }
                        if(foulingVl.data()['icon4']){
                          icon4 = true;
                        }

                        if(!this.stripIcons[stripName]['icon1'] && icon1){
                          this.stripIcons[stripName]['icon1'] = icon1;
                        }

                        if(!this.stripIcons[stripName]['icon2'] && icon2){
                          this.stripIcons[stripName]['icon2'] = icon2;
                        }
                        if(!this.stripIcons[stripName]['icon3'] && icon3){
                          this.stripIcons[stripName]['icon3'] = icon3;
                        }
                        if(!this.stripIcons[stripName]['icon4'] && icon4){
                          this.stripIcons[stripName]['icon4'] = icon4;
                        }
                        if(foulingVl.data()['damaged']){
                          this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage, 'icon1': foulingVl.data()['icon1'], 'icon2': foulingVl.data()['icon2'], 'icon3': foulingVl.data()['icon3'], 'icon4': foulingVl.data()['icon4']});
                        }else{
                          this.stripDetail[stripName].push({'colour': foulingVl.data()['colour'], 'percentage': detailsPercentage, 'icon1': foulingVl.data()['icon1'], 'icon2': foulingVl.data()['icon2'], 'icon3': foulingVl.data()['icon3'], 'icon4': foulingVl.data()['icon4']});
                        }
                      }
                    });
                  }
                });
              });
            }
          });
        });
      }
    });
  }

  initDrag(): void{
    const dragStart$ = fromEvent<MouseEvent>(this.element, "mousedown");
    const dragEnd$ = fromEvent<MouseEvent>(this.document, "mouseup");
    const drag$ = fromEvent<MouseEvent>(this.document, "mousemove").pipe(
      takeUntil(dragEnd$)
    );

    let initialY: number,
      currentY = 0;

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialY = event.clientY - currentY;

      dragSub = drag$.subscribe((event: MouseEvent) => {
        this.mouseDown = true;
        currentY = event.clientY - initialY;
        if(currentY < 100 && currentY > -1 ){
          this.element.style.top = currentY+"%";
        }
      });
    });

    const dragEndSub = dragEnd$.subscribe(() => {
      if(this.mouseDown){
        initialY = currentY;
        if(currentY < 100 && currentY > -1 ){
          this.setCurrentTime(currentY);
          this.vimeo.videoUpdate = currentY;
        }
        this.mouseDown = false;
      }
      if(dragSub){
        dragSub.unsubscribe();
      }
    });
  }

  setCurrentTime(currentY: number){
    let timestamp = (currentY/100) * this.vimeo.duration;
    this.vimeo.changeVideoTime(timestamp);
  }

  changeContent(button: string): void{
    switch (button){
      case "btn1": {
        this.activeButton = button;
        this.content = "details";
        this.inspecNav.setPage("details");
        break;
      }
      case "btn2": {
        this.activeButton = button;
        this.content = "user";
        this.inspecNav.setPage('user');
        break;
      }
      case "btn3": {
        this.activeButton = button;
        this.content = "interactive";
        this.inspecNav.setPage("interactive");
        break;
      }
      case "btn4":{
        this.activeButton = button;
        this.content = "intertrac";
        this.inspecNav.setPage("intertrac");
        break;
      }
    }
  }

  changeStarboard(starboard: boolean): void{
    this.starboard = starboard;
  }

  getStripSides(){
    let strips = this.db.collection('vessel').doc(this.id).collection('inspectionPoint').valueChanges().forEach(element => {
      this.stripsId = element;
      element.forEach(elem => {
        if(elem['shipside'] == 'starboard'){
          this.starboardSelectedStrips.push(elem['strip']);
          this.stripIcons[elem['strip'] + "s"] = {icon1: false, icon2: false, icon3: false, icon4: false}
        }else if(elem['shipside'] == 'port'){
          this.portSelectedStrips.push(elem['strip']);
          this.stripIcons[elem['strip'] + "p"] = {icon1: false, icon2: false, icon3: false, icon4: false}
        }
      });
    });
  }

  getStripVisible(strip, shipside){
    if(shipside == "starboard"){
      return this.starboardSelectedStrips.indexOf(strip) == -1
    }else {
      return this.portSelectedStrips.indexOf(strip) == -1
    }
  }

  inspectionStrip(strip, shipSide): void{
    if(shipSide == "starboard"){
      let tempStrip = this.starboardSelectedStrips.find(x => x == strip);
      this.stripsId.forEach(element => {
        if(element['shipside'] == "starboard"){
          if(element['strip'] == tempStrip){
            this.currentStrip = element;
          }
        }
      });
    }else{
      let tempStrip = this.portSelectedStrips.find(x => x == strip);
      this.stripsId.forEach(element => {
        if(element['shipside'] == "port"){
          if(element['strip'] == tempStrip){
            this.currentStrip = element;
          }
        }
      });
    }
    this.content = "upload";
    this.checkForVideo();
    this.checkForScreenshots();
    this.checkForDepthMarkers();
    this.vimeo.pauseVideo();
  }

  checkForDepthMarkers(){
    this.depthNumbers = [];
    this.draftNumbers = [];
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').get().subscribe(elm => {
      if(elm.docs){
        elm.forEach(elmF => {
          let depth = Number(elmF.data()['depth']);
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(elmF.data()['id']).collection('fouling').get().subscribe(foulingCol => {
            if(foulingCol.docs){
              foulingCol.forEach(fouling => {
                if(fouling.data()){
                  this.depthNumbers.push({depth: depth, value: fouling.data()['depthValue'], id: elmF.data()['id']});
                  this.draftNumbers.push({depth: depth, value: fouling.data()['draftValue'], id: elmF.data()['id']});
                }
              })
            }

          })
        })
      }
    });
  }

  checkForVideo(){
    //this.vimeo.removeVideo();
    //if video exists, set it
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).get().subscribe(data => {
      if(data.exists){
        if(!data.data()['videoLink']){
          this.vimeoUpload.setUploaded(false);

        }else if(data.data()['videoLink'] != null){
          //this.vimeo.loadVideo(data.data()['videoLink']);
          this.vimeoUpload.setUploaded(true);
          this.vimeo.videoLink = data.data()['videoLink'];
          this.vimeo.loadVideo(data.data()['videoLink']);

        }
        this.vimeo.pauseVideo();
      }
    });
    //if not remove video and flag for upload
    this.vimeoUpload.setUploaded(false);
  }

  checkForScreenshots(){
    this.screenShotId = [];
    this.foulingColours = [];
    this.damagedMarkers = [];
    let length = this.screenShotId.length-1;

    this.screenshots = [];

    this.screenShotActive = false;
    this.currentScreenshotId = null;
    //Check for exisiting ones on the DB
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').get().subscribe(col =>{
      if(!col.empty){
        col.docs.forEach(doc =>{
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(doc.id).collection('fouling').get().subscribe(col => {
            if(!col.empty){
              col.docs.forEach(fDoc => {
                if(fDoc.data()['colour']){
                  this.foulingColours.push({'colour': fDoc.data()['colour'], 'depth': doc.data()['depth']-1.83});
                }
                if(fDoc.data()['damaged']){
                  this.damagedMarkers.push({'depth': doc.data()['depth'] -0.43, 'screenshot': doc.id});
                }

                this.generateScreenshotIconWTime(doc.data()['depth'], doc.data()['videoTime'], doc.id, fDoc.id);
              })
            }
          })

        })
      }
    })
  }

  async generateScreenshotIcon(screenshotId, foulingId){
    let currentVideoTime = 0;
    this.depthInput = 0;
    this.draftInput = 0;
    await this.vimeo.getCurrentTime().then((val) => {
      currentVideoTime = val;
    });

    this.screenShotId.push({screenshot: screenshotId, fouling: foulingId});
    let length = this.screenShotId.length-1;

    this.screenshots.push({"videoTime": currentVideoTime, "videoUpdate": this.vimeo.videoUpdate, "length": length});

    this.screenShotActive = true;
    this.currentScreenshotId = this.screenShotId[length];
    this.toastr.remove(this.screenshotToast);
    this.disableScreenshot = false;
  }

  async generateScreenshotIconWTime(videoUpdate, videoTime, screenshotId, foulingId){
    this.screenShotId.push({screenshot: screenshotId, fouling: foulingId});
    let length = this.screenShotId.length-1;

    this.screenshots.push({"videoTime": videoTime, "videoUpdate": videoUpdate, "length": length});
  }

  getScreenshot(timestamp, videoUpdate, index){
    this.selectedIcons = [];
    this.vimeo.pauseVideo();
    //Apply video time
    this.vimeo.changeVideoTime(timestamp);
    //move bar
    this.vimeo.setVideoUpdate(videoUpdate);

    this.currentScreenshotId = this.screenShotId[index];

    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(this.currentScreenshotId['screenshot']).collection('fouling').doc(this.currentScreenshotId['fouling']).get().subscribe(data => {
      this.damaged = data.data()['damaged'] ? data.data()['damaged'] : false;
      if(data.data()['icon1']){
        this.selectedIcons.push(1);
      }
      if(data.data()['icon2']){
        this.selectedIcons.push(2);
      }
      if(data.data()['icon3']){
        this.selectedIcons.push(3);
      }
      if(data.data()['icon4']){
        this.selectedIcons.push(4);
      }
      this.setFoulingValues( data.data()['sRating'], data.data()['wRating'], data.data()['aRating'], data.data()['a2Rating']);

      this.depthInput = data.data()['depthValue'];
      this.draftInput = data.data()['draftValue'];
    })
    this.screenShotActive = true;
  }

  setFoulingValues(s, w, a, a2){
    this.foulingRatings[0] = s;
    this.foulingRatings[1] = w;
    this.foulingRatings[2] = a;
    this.foulingRatings[3] = a2;
  }

  nextStrip(){
    let stripsListLength = this.stripsId.length
    let stripCurrentIndex = this.stripsId.findIndex(x => x.id == this.currentStrip['id']);

    if(stripCurrentIndex == stripsListLength - 1){
      let stripCurrent = this.stripsId[0];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }else{
      let stripCurrent = this.stripsId[stripCurrentIndex+1];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }
  }

  previousStrip(){
    let stripsListLength = this.stripsId.length
    let stripCurrentIndex = this.stripsId.findIndex(x => x.id == this.currentStrip['id']);

    if(stripCurrentIndex == 0){
      let stripCurrent = this.stripsId[stripsListLength - 1];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }else{
      let stripCurrent = this.stripsId[stripCurrentIndex-1];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }
  }

  playVideo(){
    this.screenShotActive = false;
    this.setValuesToZero();
    this.currentScreenshotId = null;
    this.vimeo.playVideo();
    this.damaged = false;
    this.selectedIcons = [];
  }

  setValuesToZero(){
    for(var i = 0; i < 4; i++){
      this.foulingRatings[i] = 0;
    }
  }

  pauseVideo(){
    this.vimeo.pauseVideo();
  }

  changeVideoUploadOpen(){
    this.videoUploadOpen = !this.videoUploadOpen;
  }

  uploadFile(files: FileList){
    this.fileToUpload = files.item(0);
    this.vimeoUpload.setloading(true);
    let shipSide = this.starboard ? "starboard" : "port"
    this.videoLink = this.vimeoUpload.uploadToVimeo(this.fileToUpload, this.vessel['vesselName'], this.currentStrip, shipSide, this.id);
  }

  async createScreenshot(){
    this.disableScreenshot = true;
    this.screenshotToast = this.toastr.info("Generating your screenshot now...", "Please Wait!", {
      disableTimeOut: true
    }).toastId;

    this.vimeo.pauseVideo();

    let thumbnailLink = null;

    await this.vimeo.createThumbnail().then((val) => {
      let sizes = [];
      sizes = val['sizes'];
      thumbnailLink = sizes[sizes.length-1]['link'];
    });

    let currentVideoTime = 0;
    await this.vimeo.getCurrentTime().then((val) => {
      currentVideoTime = val;
    });

    let id = this.db.createId();
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(id).set({videoTime: currentVideoTime, depth: this.vimeo.videoUpdate, pictureLink: thumbnailLink, id: id});
    let foulingId = this.db.createId();
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(id).collection('fouling').doc(foulingId).set({id: foulingId, sRating: 0, wRating: 0, aRating: 0, a2Rating: 0});
    this.generateScreenshotIcon(id, foulingId);
  }

  backToOverview(){
    this.content = "vessel";
  }

  getSliderPercentage(slider){
    return this.foulingRatings[slider];
  }

  setSliderPercentage(slider, value){
    this.foulingRatings[slider] = value;

    return value;
  }

  async saveFoulingRating(){
    if(this.depthInput > 999 || this.draftInput > 999){
      this.toastr.error("Your depth or draft number is invalid.", "Input Error!")
      return;
    }
    let colour = null;

    this.depthNumbers.forEach(elm => {
      if(elm['id'] == this.currentScreenshotId['screenshot']){
        this.depthNumbers.splice(this.depthNumbers.indexOf(elm), 1);
      }
    });

    this.draftNumbers.forEach(elm => {
      if(elm['id'] == this.currentScreenshotId['screenshot']){
        this.draftNumbers.splice(this.draftNumbers.indexOf(elm), 1);
      }
    });

    let hRating = (this.foulingRatings[0] * 0.08) + (this.foulingRatings[1] * 0.1) + (this.foulingRatings[3] * 0.25) + (this.foulingRatings[2] * 0.4);

    if(hRating < 7 && hRating > 0){
      colour = "#1dff00";
    }else if(hRating >= 7 && hRating < 12){
      colour = '#FFC400';
    }else if(hRating >= 12){
      colour = '#FF0700';
    }

    let currentVideoTime = await this.vimeo.getCurrentTime();

    let percentage = (currentVideoTime/this.vimeo.duration)*100;

    this.foulingColours.push({'colour': colour, 'depth': this.vimeo.videoUpdate-1.83});

    if(this.damaged){
      this.damagedMarkers.push({'depth': this.vimeo.videoUpdate-0.43, 'screenshot': this.currentScreenshotId['screenshot']});
    }else{
      this.damagedMarkers.forEach(damage => {
        if(damage['screenshot'] == this.currentScreenshotId['screenshot']){
          const index = this.damagedMarkers.indexOf(damage, 0);
          this.damagedMarkers.splice(index, 1);
        }
      })
    }

    let icon1 = false;
    let icon2 = false;
    let icon3 = false;
    let icon4 = false;

    if(this.selectedIcons.length > 0){
      this.selectedIcons.forEach(icon => {
        if(icon == 1){
          icon1 = true;
        }else if(icon == 2 ){
          icon2 = true;
        }else if(icon == 3 ){
          icon3 = true;
        }else if(icon == 4 ){
          icon4 = true;
        }
      })
    }

    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(this.currentScreenshotId['screenshot']).collection('fouling').doc(this.currentScreenshotId['fouling']).update({sRating: this.foulingRatings[0], wRating: this.foulingRatings[1], aRating: this.foulingRatings[2], a2Rating: this.foulingRatings[3], colour: colour, percentage: percentage, damaged: this.damaged,
        icon1: icon1, icon2: icon2, icon3: icon3, icon4: icon4, depthValue: this.depthInput, draftValue: this.draftInput});

    let shipSide = this.starboard ? 's' : 'p';

    let strip = this.currentStrip['strip']+shipSide;

    this.stripDetail[strip] = [];

    this.depthNumbers.push({depth: this.vimeo.videoUpdate-.5, value: this.depthInput, id: this.currentScreenshotId['screenshot']});
    this.draftNumbers.push({depth: this.vimeo.videoUpdate-.5, value: this.draftInput, id: this.currentScreenshotId['screenshot']});

    this.setupStripDetail();
  }

  screenshotActive(index){
    if(this.currentScreenshotId != null){
      return this.currentScreenshotId == this.screenShotId[index];
    }
    return false;
  }

  getStripDetails(strip, shipSide){
    return this.stripDetail[strip + shipSide];
  }

  addInspectionPoints(){
    this.step = 2;
  }

  stripSelected(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      this.starboardSelectedStrips.push(stripNumber);
    }
    if(shipSide === "port"){
      this.portSelectedStrips.push(stripNumber);
    }
  }

  stripDeleted(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      if(this.starboardSelectedStrips.indexOf(stripNumber) != -1){
        let match = false;
        let id = null;
        this.stripsId.forEach(val => {
          if(val['shipside'] == 'starboard'){
            console.log(stripNumber);
            if(val['strip'] == stripNumber){
              match = true;
              id = val['id'];
              console.log(val);
              console.log(val['id']);
            }
          }
        });
        if(match){
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(id).delete().then(() => {
            this.starboardSelectedStrips.splice(this.starboardSelectedStrips.indexOf(stripNumber), 1);
          }).catch(error => {console.log(error)});
        }
        this.starboardSelectedStrips.splice(this.starboardSelectedStrips.indexOf(stripNumber), 1);
      }
    }
    if(shipSide === "port"){
      if(this.portSelectedStrips.indexOf(stripNumber) != -1){
        let match = false;
        let id = null;
        this.stripsId.forEach(val => {
          if(val['shipside'] == 'port'){
            if(val['strip'] == stripNumber){
              match = true;
              id = val['id'];
              console.log(val);
            }
          }
        });
        if(match){
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(id).delete().then(() => {
            console.log("deleted");
          })
        }
        this.portSelectedStrips.splice(this.portSelectedStrips.indexOf(stripNumber), 1);
        //Check if on database and delete accordingly
      }
    }
  }

  nextStep(){
    this.starboardSelectedStrips.forEach(element => {
      let match = false;
      console.log(element);
      this.stripsId.forEach(val => {
        if(val['shipside'] == 'starboard'){
          if(val['strip'] == element){
            console.log(val);
            match = true;
          }
        }
      });

      let currentStrip = this.stripsId.find(x => x.strip == element);

      if(currentStrip){
        if(currentStrip['shipSide']){}
      }

      if(!match){
        console.log("here");
        let section = this.getVesselSection("starboard", element);
        let id = this.db.createId();
        this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(id).set({shipside: "starboard", strip: element, section: section, id: id});
        this.stripsId.push({strip: element, id: id, shipside: "starboard", section: section});
      }
    });
    this.portSelectedStrips.forEach(element => {
      let match = false;
      this.stripsId.forEach(val => {
        if(val['shipside'] == 'port'){
          if(val['strip'] == element){
            console.log(val);
            match = true;
          }
        }
      });
      if(!match){
        let section = this.getVesselSection("port", element);
        let id = this.db.createId();
        this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(id).set({shipside: "port", strip: element, section: section, id: id});
        this.stripsId.push({strip: element, id: id, shipside: "port", section: section});
      }
    });
    this.step = 3;
  }

  getVesselSection(side: string, strip){
    //STARBOARD                 PORT
    //0-3 AFT                   28-31
    //4-19 MID                  12-27
    //20-25 SHOULDER            6-11
    //26-31 BOW                 0-5
    if(side == "starboard"){
      if(strip >= 0 && strip < 4){
        return "AFT";
      }else if(strip >= 4 && strip < 20){
        return "MIDSHIP";
      }else if (strip >= 20 && strip < 26){
        return "SHOULDER";
      }else{
        return "BOW";
      }
    }else{
      if(strip >= 0 && strip < 6){
        return "BOW";
      }else if(strip >= 6 && strip < 12){
        return "SHOULDER";
      }else if (strip >= 12 && strip < 28){
        return "MIDSHIP";
      }else{
        return "AFT";
      }
    }
  }

  async uploadPhoto(file: FileList){
    if(await this.authService.checkRoleMultAsync(['admin', 'inspector'])){
      let toastId = this.toastr.info("Please wait while the image is uploaded...", "Uploading!", {
        disableTimeOut: true
      }).toastId;
      const fileToUpload = file.item(0);
      if(fileToUpload.type.split('/')[0] === 'image'){
        const path = `inspection/${new Date().getTime()}_${fileToUpload.name}`;
        let ref = this.afStorage.ref(path);

        let task = this.afStorage.upload(path, fileToUpload).then(val => {
          this.afStorage.ref(path).getDownloadURL().subscribe(data => {
            this.photo = data;
            this.db.collection('inspection').doc(this.inspection.id).update({photoLink: data});
          });
        }).then(() => this.toastr.remove(toastId)).catch(() => {
          this.toastr.error("There was a problem uploading the image, please try again.", "Upload Error!");
        });
      }
    }
  }

  setDamaged(){
    this.damaged = !this.damaged;
  }

  selectIcon(icon: number){
    if(this.selectedIcons.includes(icon)){
      this.selectedIcons.splice(this.selectedIcons.indexOf(icon), 1);
    }else{
      this.selectedIcons.push(icon);
    }
  }

  iconSelected(icon: number){
    return this.selectedIcons.includes(icon) ? true : false;
  }

  iconShown(strip, section, icon){
    let iconCheck = "icon" + icon;
    return this.stripDetail[strip + section][iconCheck] ? this.stripDetail[strip + section][iconCheck]: false;
  }

  uploadPDF(file: FileList){
    let toastId = this.toastr.info("Please wait while the file is uploaded...", "Uploading!", {
      disableTimeOut: true
    }).toastId;
    const fileToUpload = file.item(0);
    if(fileToUpload.type.split('/')[1] === 'pdf'){
      console.log(fileToUpload.type);
      const path = `vessel/${new Date().getTime()}_${fileToUpload.name}`;
      let ref = this.afStorage.ref(path);

      let task = this.afStorage.upload(path, fileToUpload).then(val => {
        this.afStorage.ref(path).getDownloadURL().subscribe(data => {
          this.vesselReport = this.sanitiser.bypassSecurityTrustResourceUrl(data);
          this.db.collection('inspection').doc(this.id).update({reportPdf: data});
        });
      }).then(() => this.toastr.remove(toastId)).catch(() => {
        this.toastr.error("There was a problem uploading the file, please try again.", "Upload Error!");
      });;
    }
  }

  getStripIcons(side, icon, strip){
    try{
      if(icon == 1){
        return !this.stripIcons[strip + side]['icon1'];
      }
      if(icon == 2){
        return !this.stripIcons[strip + side]['icon2'];
      }
      if(icon == 3){
        return !this.stripIcons[strip + side]['icon3'];
      }
      if(icon == 4){
        return !this.stripIcons[strip + side]['icon4'];
      }
      return true;
    }catch(err){
      return false;
    }
  }

  srubVideo(value){
    let timestamp = (value/100) * this.vimeo.duration;
    this.vimeo.changeVideoTime(timestamp);
  }

  refresh(){
    location.reload();
  }
}
