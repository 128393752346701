<app-header></app-header>
<div class="title-bar">
  <span>{{inspection['vesselName']}}</span>
  <span>{{' - ' + inspection['portName']+' - '}}</span>
  <span>{{inspection['date'] | date}}</span>
</div>

<div class="left-function-bar">

  <div class="btn" (click)="changeContent('btn1')" [ngClass]="{'btn-activated': activeButton == 'btn1'}">
    <div class="icon">
      <img src="assets/images/noun-inspection-1951701.png" alt="">
    </div>
    <span>Vessel Inspection</span>
  </div>
  <div class="btn" (click)="changeContent('btn3')" [ngClass]="{'btn-activated': activeButton == 'btn3'}">
    <div class="icon">
      <img src="assets/images/noun-summary-report-2457874.png" alt="">
    </div>
    <span>Executive Summary</span>
  </div>
  <div class="btn" (click)="changeContent('btn4')" [ngClass]="{'btn-activated': activeButton == 'btn4'}">
    <div class="icon">
      <img src="assets/images/noun-report-5160679.png" alt="">
    </div>
    <span>Vessel Activity Report</span>
  </div>
  <div class="btn" (click)="changeContent('btn2')" [ngClass]="{'btn-activated': activeButton == 'btn2'}" *ngIf="authService.checkRolesMult(['admin', 'inspector'])">
    <div class="icon">
      <img src="assets/images/icons/noun-clients-4200490@2x.png" alt="">
    </div>
    <span>Sharing with Clients</span>
  </div>

</div>
<div class="content display-content" *ngIf="content == 'details'">
  <div class="title-container">
    <div class="title">Inspection Details</div>
    <div class="buttons">
      <div class="save-button" (click)="transects()" *ngIf="!editEnabled">
        View Transects
      </div>
      <div class="save-button" (click)="editEnabled = !editEnabled" *ngIf="editEnabled && authService.checkRolesMult(['admin', 'inspector'])">
        Save Details
      </div>
      <div class="back-button" (click)="overview()">
        Back
      </div>
    </div>
  </div>

  <div class="details-input">

    <mat-card>
      <div class="edit-btn" (click)="editEnabled = !editEnabled" *ngIf="!editEnabled && authService.checkRolesMult(['admin', 'inspector'])">Edit Details</div>
      <div class="vessel-details">
        <div class="picture">
          <label class="picture-upload-label" for="pictureUpload">
            <img src="{{photo}}">
          </label>
          <input (change)="uploadPhoto($event.target.files)" [hidden]="true"
            accept="image/jpeg, image/apng, image/avif, image/gif, image/png, image/svg+xml, image/webp" id="pictureUpload"
            type="file" [disabled]="authService.checkRolesMult(['client'])">
          <label for="pictureUpload" class="save-button" *ngIf="authService.checkRolesMult(['admin', 'inspector'])">
            <span>Upload Inspection Image</span>
          </label>
        </div>
      </div>
      <div class="details">
        <div class="detail-item">
          <span>Vessel Name:</span>
          <span class="item">{{vessel['name']}}</span>
        </div>
        <div class="detail-item">
          <span>Inspection Date:</span>
          <span class="item">{{inspection['date'] | date: 'medium'}}</span>
        </div>
        <div class="detail-item">
          <span>Inspection Port:</span>
          <span class="item">{{tempPortName}}</span>
        </div>
        <div class="detail-item">
          <span>Operator/Inspector:</span>
          <span class="item">{{inspectorString}}</span>
        </div>
        <div class="detail-item">
          <span>Weather:</span>
          <span class="item">{{weatherString}}</span>
        </div>
        <div class="detail-item">
          <span>Water Visibility:</span>
          <span *ngIf="!editEnabled">{{tempVisibility}}</span>
          <input *ngIf="editEnabled" list="visibilities" id="visibility" [(ngModel)]="tempVisibility" (ngModelChange)="updateVisibility($event)" placeholder="Type to search water visibility...">
          <datalist *ngIf="editEnabled" name="visibilities" id="visibilities" [(ngModel)]="tempVisibility">
            <option *ngFor="let vis of visibilities" [ngValue]="vis.visibility">{{vis.visibility}}</option>
          </datalist>
        </div>
        <div class="detail-item">
          <span>Coating Flats:</span>
          <span *ngIf="!editEnabled">{{tempCoatingFlat}}</span>
          <input *ngIf="editEnabled" list="flats" id="flat" [(ngModel)]="tempCoatingFlat" (ngModelChange)="updateCoatingFlat($event)" placeholder="Type to search coating...">
          <datalist *ngIf="editEnabled" name="flats" id="flats" [(ngModel)]="tempCoatingFlat">
            <option *ngFor="let coating of coatings" [ngValue]="coating.coating">{{coating.coating}}</option>
          </datalist>
        </div>
        <div class="detail-item">
          <span>Coating Verticals:</span>
          <span *ngIf="!editEnabled">{{tempCoatingVert}}</span>
          <input *ngIf="editEnabled" list="verts" id="vert" [(ngModel)]="tempCoatingVert" (ngModelChange)="updateCoatingVert($event)" placeholder="Type to search coating...">
          <datalist *ngIf="editEnabled" name="verts" id="verts" [(ngModel)]="tempCoatingVert">
            <option *ngFor="let coating of coatings" [ngValue]="coating.coating">{{coating.coating}}</option>
          </datalist>
        </div>
        <div class="detail-item">
          <span>Coating Boottop:</span>
          <span *ngIf="!editEnabled">{{tempCoatingBoot}}</span>
          <input *ngIf="editEnabled" list="boots" id="boot" [(ngModel)]="tempCoatingBoot" (ngModelChange)="updateCoatingBoot($event)" placeholder="Type to search coating...">
          <datalist *ngIf="editEnabled" name="boots" id="boots" [(ngModel)]="tempCoatingBoot">
            <option *ngFor="let coating of coatings" [ngValue]="coating.coating">{{coating.coating}}</option>
          </datalist>
        </div>
        <div class="detail-item">
          <span>Inspection State:</span>
          <span *ngIf="!editEnabled">{{caseStatus}}</span>
          <select *ngIf="editEnabled" (ngModelChange)="updateInspectionStatus($event)" [(ngModel)]="caseStatus" class="item">
            <option [ngValue]="'Inspection'">Inspection</option>
            <option [ngValue]="'Closed'">Closed</option>
          </select>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<div class="content display-content" *ngIf="content == 'interactive'">
  <app-interactive-report [inspection]="inspection"></app-interactive-report>
</div>
<div class="content display-content" *ngIf="content == 'intertrac'">
  <div class="vessel-activity">
    <div class="pdf-btn">
      <label for="pdfUpload">
        <div class="button" *ngIf="authService.checkRolesMult(['admin', 'inspector'])">
          <span>Click to upload Vessel Report</span>
        </div>
      </label>
      <input (change)="uploadPDF($event.target.files)" accept="application/pdf" id="pdfUpload"
        type="file" [hidden]="true">
    </div>
    <h1>Vessel Activity</h1>
    <div [hidden]="vesselReport == null || vesselReport == undefined" class="pdf">
      <object [data]="vesselReport" type="application/pdf" *ngIf="vesselReport">
        <embed [src]="vesselReport" type="application/pdf">
      </object>
    </div>
    <div>
      <h2>No Report Uploaded</h2>
    </div>
  </div>
</div>
<div class="content display-content" *ngIf="content == 'user'">
  <app-attach-user [inspection]="inspection"></app-attach-user>
</div>
