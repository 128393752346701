<div class="content">
  <div class="title-container">
    <h1 class="overview-title">Client Access</h1>
    <div class="buttons">
      <input type="text" class="search" id="search" [(ngModel)]="searchText" placeholder="Type to search company, email or name...">
      <div class="save-button" (click)="saveUserAccess()">
        Save Access
      </div>
    </div>
  </div>
  <div class="table-container">
    <table class="table-case">
      <tr class="title-tr">
        <td>
          <i class="fas fa-chevron-up up" (click)="filterList('name', 1)"
          [ngClass]="{'selected': filterSelected('name', 1)}"></i>
          <span>Name</span>
          <i class="fas fa-chevron-down down" (click)="filterList('name', 2)"
          [ngClass]="{'selected': filterSelected('name', 2)}"></i>
        </td>
        <td>
          <i class="fas fa-chevron-up up" (click)="filterList('company', 1)"
          [ngClass]="{'selected': filterSelected('company', 1)}"></i>
          <span>Company</span>
          <i class="fas fa-chevron-down down" (click)="filterList('company', 2)"
          [ngClass]="{'selected': filterSelected('company', 2)}"></i>
        </td>
        <td>
          <i class="fas fa-chevron-up up" (click)="filterList('email', 1)"
          [ngClass]="{'selected': filterSelected('email', 1)}"></i>
          <span>Email Address</span>
          <i class="fas fa-chevron-down down" (click)="filterList('email', 2)"
          [ngClass]="{'selected': filterSelected('email', 2)}"></i>
        </td>
        <td>
          <span>Provide Access</span>
        </td>
      </tr>
      <tr class="content-tr" *ngFor="let user of users | async | filter: searchText">
        <td>
          <span>{{user['name']}}</span>
        </td>
        <td>
          <span>{{user['company']}}</span>
        </td>
        <td>
          <span>{{user['email']}}</span>
        </td>
        <td>
          <div class="access-granted" *ngIf="userHasAccess(user.id, user.role.role)" (click)="removeUserAccessFromArray(user.id, user.role.role)">
            <div class="icon">
              <img src="assets/images/icons/noun-tick-3923816@2x.png" alt="">
            </div>
            <span>Access Gained</span>
          </div>
          <div class="access-not">
            <div class="icon" *ngIf="!userHasAccess(user.id, user.role.role)" (click)="addUserAccessToArray(user.id)">
              <img src="assets/images/icons/noun-add-5327046@2x.png" alt="">
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
