import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthServiceService } from '../auth-service.service';
import { LoadingService } from '../loading.service';
import { HeaderService } from '../service/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router,
      private loadingService: LoadingService,
      private afAuth: AngularFireAuth,
      private headerService: HeaderService,
      private cookieService: CookieService,
      public authService: AuthServiceService) { }

  loadingScreen 	 = false;

  ngOnInit(): void {
  }

  public newcase(): void {
    this.router.navigate(['/inspection'], { queryParams: { id: 'new' } });
  }

  public logout(): void {
    this.loadingService.setLoadingScreen(true);
    this.afAuth.signOut().then(val => {
      setTimeout(() => {
        localStorage.removeItem('intSection');
        this.cookieService.delete('role');
        this.router.navigate(['/login/']);
     }, 2000);
    });
  }

  public overview(): void {
    this.router.navigate(['/overview/']);
  }

  getCurrentPage(){
    return this.headerService.CurrentPage;
  }
}
