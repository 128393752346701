import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value, searchText: string): unknown {
    if(searchText == null){
      return value;
    }
    return value.filter(it => {
      return it.name.toLowerCase().includes(searchText.toLowerCase().trim())
        || it.email.toLowerCase().includes(searchText.toLowerCase().trim()) || it.company.toLowerCase().includes(searchText.toLowerCase().trim())
    });
  }

}
