import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { HeaderService } from '../service/header.service';
import swal from 'sweetalert';
import { LoadingService } from '../loading.service';
import { UserService } from '../service/user.service';

const emailVal = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  name: string = null;
  email: string = null;
  company: string = null;
  role: string = null;

  roleId: string = null;

  customers = [];

  roles: Array<string> = ['admin', 'inspector', 'client'];

  constructor(
    private authService: AuthServiceService,
    private db: AngularFirestore,
    private router: Router,
    private headerService: HeaderService,
    private loadingService: LoadingService,
    public userService: UserService) { }

  async ngOnInit(): Promise<void> {
    //header set here
    if(await this.userService.idSet()){
      (await this.db.collection('user').doc(this.userService.id).get()).subscribe((users) => {
        if(users.exists){
          let user: any = users.data();
          this.name = user.name;
          this.email = user.email;
          this.company = user.company.name;
          this.role = user.role.role;
          this.roleId = user.role.id;
        }else{
          swal('User Does Not Exist', 'If you think this is an error, contact a system administrator', 'error').then(() => {
            this.userService.resetUser();
            this.router.navigate(['users']);
          })
        }
      })
    }
    this.db.collection('customer').get().subscribe((custs) => {
      if(!custs.empty){
        custs.forEach(cust => {
          this.customers.push(cust.data());
        })
      }
    })
    this.headerService.CurrentPage = "user"
  }

  async saveUser() {
    this.loadingService.setLoadingScreen(true)
    if(this.userService.id == null){
      let validate = await this.validateUser();
      if (validate) {
        swal("Error", validate, 'error');
      } else {
        let customerId = this.customers.find(x => x.name == this.company);
        if(customerId == undefined){
          customerId.id = this.db.createId();
          customerId.name = this.company;
          this.db.collection('customer').doc(customerId).set({
            id: customerId,
            name: this.company
          })
        }
        (await this.authService.addUser(this.name, this.email, customerId, this.role).then(res => {
          res.subscribe((r2:any) => {
            if (r2.success) {
              swal("User added", 'The user has successfully been added. Their password is: ' + r2.data + ' please email the user their password as well as directions on how to access.', 'success').then(() => {
                this.loadingService.setLoadingScreen(false);
                this.router.navigate(['/users'])
              });
            } else {
              swal("Something went wrong", r2.message, 'error').then(() => {
                this.loadingService.setLoadingScreen(false);
              });
            }
          })
        }).catch((e) => {console.log(e); this.loadingService.setLoadingScreen(false);}))
      }
    }else{
      let validate = await this.validateEditUser();
      if(validate){
        swal("Error", validate,'error');
      }else{
        let customerId = this.customers.find(x => x.name == this.company);
        if(customerId == undefined){
          customerId.id = this.db.createId();
          customerId.name = this.company;
          this.db.collection('customer').doc(customerId).set({
            id: customerId,
            name: this.company
          })
        }
        let user = {
          id: this.userService.id,
          name: this.name,
          email: this.email,
          company: customerId,
          role: {
            id: this.roleId,
            role: this.role
          }
        }
        this.authService.editUser(user).then(res => {
          res.subscribe((r2: any) => {
            this.loadingService.setLoadingScreen(false);
            if (r2.success) {
              swal("User Updated", 'The user has successfully been updated.', 'success').then(() => {
                this.loadingService.setLoadingScreen(false);
                this.userService.resetUser();
                this.router.navigate(['/users'])
              });
            } else {
              swal("Something went wrong", r2.message, 'error').then(() => {
                this.loadingService.setLoadingScreen(false);
              });
            }
          })
        }).catch((e) => {console.log(e); this.loadingService.setLoadingScreen(false);})
      }
    }
  }

  async validateEditUser(){
    if(!emailVal.test(this.email)){
      return "Email Address Invalid";
    } else if (this.name == null || this.name.trim() == "") {
      return "Name is required";
    } else if (this.company == null || this.company.trim() == "") {
      return "Company is required";
    } else if (this.role == null || this.role.trim() == "") {
      return "Role is required";
    } else if (!this.roles.includes(this.role)) {
      return "Role doesn't match the selection";
    } else {
      return null;
    }
  }

  async validateUser() {
    let emailMatch = (await this.db.collection('user').ref.where('email', '==', this.email.trim()).get()).size != 0;

    if (emailMatch) {
      return "Email already exists";
    }else if(!emailVal.test(this.email)){
      return "Email Address Invalid";
    } else if (this.name == null || this.name.trim() == "") {
      return "Name is required";
    } else if (this.company == null || this.company.trim() == "") {
      return "Company is required";
    } else if (this.role == null || this.role.trim() == "") {
      return "Role is required";
    } else if (!this.roles.includes(this.role)) {
      return "Role doesn't match the selection";
    } else {
      return null;
    }
  }

  canSubmit(): boolean {
    return this.role != null && this.email != null && this.company != null && this.name != null
      && this.role.trim() != '' && this.email.trim() != '' && this.company.trim() != '' && this.name.trim() != '';
  }

  selectRole(role) {
    this.role = role;
  }
}
