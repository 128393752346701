<app-header></app-header>
<div class="content">
  <h1 class="overview-title">User List</h1>
  <div class="save-button" [routerLink]="['/users/new']">
    Create New User
  </div>
  <div class="table-container">
    <table class="table-case">
      <tr class="title-tr">
        <td>
          <i (click)="filterList('company', 1)"
            [ngClass]="{'selected': filterSelected('company', 1)}" class="fas fa-chevron-up up"></i>
          <span>Company</span>
          <i (click)="filterList('company', 2)"
            [ngClass]="{'selected': filterSelected('company', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('name', 1)"
          [ngClass]="{'selected': filterSelected('name', 1)}" class="fas fa-chevron-up up"></i>
          <span>Name</span>
          <i (click)="filterList('name', 2)"
          [ngClass]="{'selected': filterSelected('name', 2)}"
          class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('role', 1)"
            [ngClass]="{'selected': filterSelected('role', 1)}" class="fas fa-chevron-up up"></i>
          <span>Role</span>
          <i (click)="filterList('role', 2)"
            [ngClass]="{'selected': filterSelected('role', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('email', 1)"
            [ngClass]="{'selected': filterSelected('email', 1)}" class="fas fa-chevron-up up"></i>
          <span>Email Address</span>
          <i (click)="filterList('email', 2)"
            [ngClass]="{'selected': filterSelected('email', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td class="edit">
          <span>Password</span>
        </td>
        <td class="reset">
          <span>Remove Account</span>
        </td>
        <td class="edit">
          <span>Edit</span>
        </td>
      </tr>
      <tr *ngFor="let user of users | async" class="content-tr">
        <td>
          <span>{{user['company']['name']}}</span>
        </td>
        <td>
          <span>{{user['name']}}</span>
        </td>
        <td>
          <span>{{user['role']['role'] | titlecase}}</span>
        </td>
        <td>
          <span>{{user['email']}}</span>
        </td>
        <td class="icon-td" (click)="resetPassword(user.id)">
          <div class="btn">
            <i class="fas fa-key"></i>
            <span>Reset</span>
          </div>
        </td>
        <td class="img-td" (click)="deleteUser(user.id)">
          <div class="btn">
            <div class="img">
              <img src="assets/images/icons/noun-cross-2180950@2x.png" alt="">
            </div>
          </div>
        </td>
        <td class="icon-td" (click)="editUser(user.id)">
          <div class="btn edit">
            <span>Edit</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
