import { HttpClient } from '@angular/common/http';
import { Injectable,NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from "@angular/router";
import { unwatchFile } from 'fs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private role: string = null;
  private url: string = 'https://internal-express.the-podium.com';
  //private url: string = 'http://localhost:3000';
  private extention: string = '/akzo';

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,
    private cookieService: CookieService,
    private http: HttpClient
    ) { }


  async SignIn(email,password): Promise<boolean>{
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.getRoles(result.user.uid);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  async editUser(user){
    let body = {
      user: user
    }
    return this.http.patch(this.url + this.extention + '/edit', body, {headers: {Authorization: await this.getToken()}});
  }

  async addUser(name: string, email: string, company: string, role: string){
    let body = {
      email: email,
      name: name,
      role: role,
      company: company,
    }
    return this.http.post(this.url + this.extention + '/add', body, {headers: {Authorization: await this.getToken()}});
  }

  async deleteUser(userId: string){
    let body = {
      user: userId
    }

    return this.http.delete(this.url + this.extention + '/delete', {body: body, headers: {Authorization: await this.getToken()}});
  }

  async resetPassword(userId: string){
    let body = {
      user: userId
    }

    return this.http.patch(this.url + this.extention + '/reset-password', body, {headers: {Authorization: await this.getToken()}});
  }

  async getRoles(userId: string){
    let role = (await this.afs.collection('role').ref.where('userId', '==', userId).get()).docs[0].data()['role'];
    this.cookieService.set('role', role);
  }

  checkRoles(role: string): boolean{
    return this.cookieService.get('role') == role;
  }

  checkRolesMult(roles: Array<string>): boolean{
    if(this.role == null){
      this.getRole();
    }
    return roles.findIndex(x => x == this.role) != -1;
  }

  async checkRoleMultAsync(roles: Array<string>): Promise<boolean>{
    if(this.role == null){
      this.getRole();
    }
    return roles.findIndex(x => x == this.role) != -1;
  }

  async getRole(){
    this.role = await this.cookieService.get('role')
  }

  async checkAccessPriv(ids: Array<string>): Promise<boolean>{
    let uid = (await this.afAuth.currentUser).uid
    return ids.find(x => x == uid) != undefined;
  }

  async getUserRole(){
    return await this.cookieService.get('role');
  }

  async getToken(){
    return (await this.afAuth.currentUser).getIdToken(true);
  }
}
