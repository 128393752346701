import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-points',
  templateUrl: './add-points.component.html',
  styleUrls: ['./add-points.component.scss']
})
export class AddPointsComponent implements OnInit {

  stripsDisabled: boolean = true;
  vesselLength: number = 0;
  vesselType = null;
  starboard = true;
  strips = [];

  portSelectedStrips = [];
  starboardSelectedStrips = [];

  constructor() { }

  ngOnInit(): void {
  }

  stripSelected(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      this.starboardSelectedStrips.push(stripNumber);
    }
    if(shipSide === "port"){
      this.portSelectedStrips.push(stripNumber);
    }
  }

  stripDeleted(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      if(this.starboardSelectedStrips.indexOf(stripNumber) != -1){
        this.starboardSelectedStrips.splice(this.starboardSelectedStrips.indexOf(stripNumber), 1);
      }
    }
    if(shipSide === "port"){
      if(this.portSelectedStrips.indexOf(stripNumber) != -1){
        this.portSelectedStrips.splice(this.portSelectedStrips.indexOf(stripNumber), 1);
      }
    }
  }
}
